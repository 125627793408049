import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, Alert,AlertIcon, AlertTitle, } from '@chakra-ui/react';
import SearchAndFilter from './SearchAndFilter';
import CustomTable from './CustomTable';
import { useSelector } from 'react-redux';
import httpRequest from '../utils/httpRequest';
import { BASE_URL } from '../config/settings';

const EventReportDetails = () => {
    const [eventDetails, setEventDetails] = useState('');
    const [error, setError] = useState('');
    const eventData = useSelector((state) => state.modal?.formModal?.data);
    const eventId = eventData?.eventId;

    const handleEventDetails = useCallback(async() => {
        setError("");
        try {
            const response = await httpRequest(
                `${BASE_URL}/Report/GetPayerDetails?eventId=${eventId}`
            );
            if(!response.message) {
                setError("");
                setEventDetails(response);
            }else{
                setError( response?.responseDescription ||"Request failed. Please try again ");
            }

        } catch (error) {
            setError(error?.message || "Something went wrong. Please try again");
        }
        
    }, [eventId]);

    useEffect(() => {
        handleEventDetails();
    }, [handleEventDetails]);


    const tableProps = {
        data: eventDetails,
        title: {
            fullName: "Fullname",
            attendanceType: "Attendance Type",
            membershipType: "Membership type",
            amount: "Total Amount",

        },
        dataKeys: [
            "fullName",
            "attendanceType",
            "membershipType",
            "amount",

        ],
        variant: true,
        sn: true,
        transformRow: (item) => ({
            fullName: <Box cursor={'pointer'} >{item?.firstName + " " + item?.lastName}</Box> ,
            attendanceType: <Box cursor={'pointer'} >{item?.attendanceType}</Box> ,
            membershipType: <Box cursor={'pointer'} >{item?.membershipType}</Box> ,
            amount: <Box cursor={'pointer'} >{item?.amount}</Box>,
        }),
    };

  return (
    <Box minH={'80vh'}>
        {
        error &&(
        <Alert status="error" className="mt-3">
            <AlertIcon />
            <AlertTitle>{ error}</AlertTitle>
        </Alert>
        )
        }
        <Text mt={2}>{eventData?.eventName}</Text>
        {eventDetails && 
        <>
            <Flex justifyContent={'flex-end'} alignItems={'center'} my={5}>
            <SearchAndFilter />
            </Flex>
            <CustomTable {...tableProps} />
        </>
        }
    </Box>
  )
}

export default EventReportDetails;