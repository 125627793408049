import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";

const initialState = {
  data: [],
  loading: false,
  error: null,
  currentEvent: null,
  getTagDetails: "",
  eventAttendance: null,
};

export const fetchAllEvents = createAsyncThunk(
  "events/fetchAllEvents",
  async () => {
    const response = httpRequest(`${BASE_URL}/EventManagement/get-all-events`);
    return response;
  }
);

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    addEvent: (state, { payload }) => {
      state.data.push(payload);
    },
    addCurrentEvent: (state, { payload }) => {
      state.currentEvent = payload;
    },
    setgetTagDetails: (state, { payload }) => {
      state.getTagDetails = payload;
    },
    setEventAttendance: (state, { payload }) => {
      state.eventAttendance = payload;
    },

    editEvent: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllEvents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    });
    builder.addCase(fetchAllEvents.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.responseDescription;
    });
  },
});

export const selectEvents = (state) => state.events.data;
export const selectEventsById = (state, id) =>
  state.events.data.filter((item) => item.id === id);

export const { addEvent, addCurrentEvent, editEvent, setgetTagDetails, setEventAttendance } = eventSlice.actions;
export default eventSlice.reducer;
