import axios from "axios";
import storeInit from "../redux/store";

const httpRequest = async (
  url,
  method = "get",
  body = null,
  others,
  contentType = "application/json"
) => {
  const token = storeInit.store.getState()?.auth?.user?.token;
  try {
    const response = await axios({
      url,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `${contentType}`,
        language: "en",
      },
      ...others,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default httpRequest;
