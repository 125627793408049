import { combineReducers } from "redux";
import authSlice from "./authSlice";
import usersSlice from "./usersSlice";
import modalSlice from "./modalSlice";
import membershipTypeSlice from "./membershipTypeSlice";
import membershipMgtSlice from "./membershipMgtSlice";
import hotelAccomSlice from "./hotelAccomSlice";
import dashboardSlice from "./dashboardSlice";
import eventRegistrationSlice from "./eventRegistrationSlice";
import eventSlice from "./eventSlice";
import uploadSlice from "./uploadSlice";
import reportsSlice from "./reportsSlice";
import guestSpeakerSlice from "./guestSpeakerSlice";
import searchFilterSlice from "./searchFilterSlice";
import toastSlice from "./toastSlice";

const combinedSlices = combineReducers({
  auth: authSlice,
  users: usersSlice,
  modal: modalSlice,
  membershipType: membershipTypeSlice,
  dashboardSlice: dashboardSlice,
  membershipMgt: membershipMgtSlice,
  hotel: hotelAccomSlice,
  eventRegistration: eventRegistrationSlice,
  events: eventSlice,
  uploads: uploadSlice,
  reports: reportsSlice,
  guestSpeaker:guestSpeakerSlice,
  searchFilter: searchFilterSlice,
  toast: toastSlice,
});

export default combinedSlices;
