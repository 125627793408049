import { addLogoSchema } from "../utils/formValidationSchema";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { editUpload } from "../redux/slices/uploadSlice";
import { useState } from "react";
import UploadButton from "./UploadButton";

const EditLogo = () => {
  const dispatch = useDispatch();
  const currentUpload = useSelector((state) => state.uploads.currentUpload);
  console.log(currentUpload);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("Id", values.id);
    formData.append("MembershipPortalName", values.membershipPortalName);
    formData.append("DateCreated", values.dateCreated);
    formData.append("Note", values.note);
    formData.append("UploadLogo", values.uploadLogo);
    formData.append("UploadSignature", values.uploadSignature);

    setIsLoading(true);
    try {
      const response = await httpRequest(
        `${BASE_URL}/UploadManagement/UpdateUpload`,
        "post",
        formData,
        null,
        "multipart/form-data"
      );

      dispatch(editUpload(response.data));
      dispatch(
        setFormModal({
          status: false,
        })
      );
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...currentUpload,
        dateCreated: new Date(currentUpload.dateCreated)
          .toISOString()
          .split("T")[0],
      }}
      validationSchema={addLogoSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => {
        return (
          <Flex direction="column" rowGap={4} as={Form}>
            <FormControl
              isInvalid={
                errors.membershipPortalName && touched.membershipPortalName
              }
            >
              <FormLabel>Membership Portal Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="membershipPortalName"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.membershipPortalName}
              />
              {touched.membershipPortalName && errors.membershipPortalName && (
                <FormErrorMessage>
                  {errors.membershipPortalName}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.dateCreated && touched.dateCreated}>
              <FormLabel>Date Created</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="dateCreated"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                type="date"
                value={values?.dateCreated}
              />
              {touched.dateCreated && errors.dateCreated && (
                <FormErrorMessage>{errors.dateCreated}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.note && touched.note}>
              <FormLabel>Note</FormLabel>
              <Textarea
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="note"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                rows={5}
                resize={"none"}
                value={values?.note}
              />
              {touched.note && errors.note && (
                <FormErrorMessage>{errors.note}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.uploadLogo}>
              <FormLabel>Upload Logo</FormLabel>
              <Input
                name="uploadLogo"
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                onChange={(e) => {
                  setFieldValue("uploadLogo", e.target.files[0]);
                }}
                onBlur={handleBlur}
              />
              {values.uploadLogo ? (
                <div>
                  <img
                    src={URL.createObjectURL(values.uploadLogo)}
                    alt="Selected"
                  />
                </div>
              ) : (
                <UploadButton />
              )}
              {touched.uploadLogo && errors.uploadLogo && (
                <FormErrorMessage>{errors.uploadLogo}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.uploadSignature}>
              <FormLabel>Upload Signature</FormLabel>
              <Input
                name="uploadSignature"
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                onChange={(e) => {
                  setFieldValue("uploadSignature", e.target.files[0]);
                }}
                onBlur={handleBlur}
              />
              {values.uploadSignature ? (
                <div>
                  <img
                    src={URL.createObjectURL(values.uploadSignature)}
                    alt="Selected"
                  />
                </div>
              ) : (
                <UploadButton />
              )}
              {touched.uploadSignature && errors.uploadSignature && (
                <FormErrorMessage>{errors.uploadSignature}</FormErrorMessage>
              )}
            </FormControl>
            <Button
              mt={10}
              width={"100%"}
              background={"#22944E"}
              _hover={{ background: " " }}
              color={"#FFFFFF"}
              type="submit"
              isLoading={isLoading}
              loadingText="Uploading ..."
            >
              Save
            </Button>
          </Flex>
        );
      }}
    </Formik>
  );
};

export default EditLogo;
