import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    formModal: {
      status: false,
      title: "",
      type: "",
      showCloseButton: true,
      data: null,
      size: ["sm", "md", "lg"],
    },

    alertModal: {
      status: true,
      title: "",
      type: "",
      showCloseButton: true,
      data: null,
    },

    confirmModal: {
      status: false,
      type: "",
      title: "",
      desc: "",
      payload: null,
      buttonText: "",
      showCloseButton: true,
      showActionBtn: true,
      size: ["sm", "md", "lg"],
    },

    loader: {
      status: false,
      message: "",
    },

    modalToggle:false,
  },

  reducers: {
    setFormModal: (state, { payload }) => {
      state.formModal = {
        status: payload.status,
        title: payload.title,
        type: payload.type,
        showCloseButton: payload?.showCloseButton,
        data: payload.data,
        size: payload?.size || ["sm", "md", "lg"],
      };
    },
    setAlertModal: (state, { payload }) => {
      state.alertModal = {
        status: payload.status,
        title: payload.title,
        type: payload.type,
        showCloseButton: payload?.showCloseButton,
        data: payload.data,
        size: payload?.size || ["sm", "md", "lg"],
      };
    },

    setConfirmModal: (state, { payload }) => {
      state.confirmModal = {
        status: payload.status,
        type: payload.type,
        title: payload.title,
        desc: payload.desc,
        payload: payload.payload,
        buttonText: payload.buttonText,
        showCloseButton: payload?.showCloseButton,
        showActionBtn: payload.showActionBtn,
        sessionId: payload.sessionId,
        size: payload?.size || ["sm", "md", "lg"],
      };
    },

    setLoader: (state, { payload }) => {
      state.loader = {
        status: payload.status,
        message: payload.message,
      };
    },

  setModalToggle: (state) =>{
    state.modalToggle = !state.modalToggle;
  },
  
  },
});

export const { setFormModal, setLoader, setAlertModal, setConfirmModal, setModalToggle } = modalSlice.actions;
export default modalSlice.reducer;
