import React, {useState } from 'react';
import { Flex, FormControl, FormLabel, Input, Text, Button, Alert,AlertIcon, AlertTitle, } from '@chakra-ui/react';
import { BsArrowLeft } from "react-icons/bs";
import {setModalToggle} from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from 'react-redux';
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { setFormModal} from "../redux/slices/modalSlice";
import {setmemberCheckDetails} from "../redux/slices/eventRegistrationSlice";

const EventRegModal2 = () => {
    const dispatch = useDispatch();
    const [memberNo, setMemberNo] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const eventData = useSelector((state) => state?.eventRegistration?.eventDetails);
    // console.log("eventData modal", eventData);

   const handleConfirmMember = async() =>{
        setError("");
        setLoading(true);
        try {
            const response = await httpRequest(
                `${BASE_URL}/Registration/check-membership`,
                "post",
                { 
                    "memberShipCode": memberNo,
                    "eventManagementId": eventData.id, 
                }
            );

            if(response?.isSuccessful === true){
                setLoading(false);
                await dispatch(setmemberCheckDetails(response?.data));
                dispatch(setFormModal({status:true, title: "Registration Form", type:"eventRegForm"}));
            }else{
                setLoading(false);
                setError(response?.response?.data?.responseDescription || "Something went wrong. Please try again");
            }
        } catch (error) {
            setError(error?.message || "Something went wrong. Please try again");
        }finally{
            setLoading(false);
        }
   } 

  return (
    <>
        <BsArrowLeft
            style={{
            marginTop:"10px",
            width: "20px",
            height: "20px",
            color: "#292D32",
            cursor: "pointer",
            }}
            onClick={() => dispatch(setModalToggle()) }
        />
        {
        error &&(
        <Alert status="error" className="mt-3">
            <AlertIcon />
            <AlertTitle>{ error}</AlertTitle>
        </Alert>
        )
        }
        <Flex flexDirection={"column"} alignItems={'center'} mt={10}>
            <Text color={'#121212'} fontSize={'40px'} fontWeight={'700'} lineHeight={'42px'}>Membership Number</Text>
            <Text py={2} color={'#7A7A7A'} fontSize={'18px'} fontWeight={'400'} lineHeight={'24px'} mt={'10px'}>
                Kindly input your membership number below
            </Text>
            <FormControl mt={5} mb={8} w={'90%'} >
                <FormLabel htmlFor='membershipnumber'>Membership Number</FormLabel>
                <Input
                    placeholder="Enter your membership number"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="membershipnumber"
                    focusBorderColor="#ffffff"
                    borderRadius={'0'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onChange={(e) => setMemberNo(e.target.value)}
                />
            </FormControl>
            <Button
                width={"50%"}
                background={"#22944E"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                  isLoading={loading}
                loadingText="Continue ..."
                onClick={handleConfirmMember}
            >
                Continue
            </Button>
        </Flex>
    </>
  )
}

export default EventRegModal2;