import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import blueContainer from "../Assets/Images/blue-container.svg";
import orangeContainer from "../Assets/Images/orange-container.svg";
import greenContainer from "../Assets/Images/green-container.svg";
// import CreateAndFilter from "../components/layout/CreateAndFilter";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import Create from "../components/layout/Create";
import { fetchDashboard } from "../redux/slices/dashboardSlice";
import { styles } from "../Assets/css/styles";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [isLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch, isLoading]);

  const dashDetails = useSelector((state) => state.dashboardSlice.dashboard);

  const createData = {
    createText: "Add New Event",
    onClick: () => {
      dispatch(
        setFormModal({
          status: true,
          title: "Add New Event",
          type: "addEvent",
        })
      );
    },
  };

  return (
    <>
      <Flex px="14px" flexDirection="column">
        <Flex
          flexDirection={["column", "row", "row"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
          borderBottom={"1px solid #B4B1B1"}
          py={"20px"}
          px={"20px"}
          mb={5}
        >
          <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
            Dashboard
          </Text>
          <Create {...createData} />
        </Flex>
        <Flex
          width="100%"
          flexDirection={{ base: "column", md: "row", lg: "row" }}
          flexWrap="wrap"
          justifyContent="space-around"
        >
          <Flex sx={styles.dashboard.cards}>
            <Flex alignItems="center">
              <img src={blueContainer} alt="blue container" width="56px" />
              <Flex flexDirection="column" pl="16px">
                <Text fontSize="12px" fontWeight="400">
                  Total No. of Events
                </Text>
                <Text fontSize="24px" fontWeight="700">
                  {dashDetails?.totalEvents}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex sx={styles.dashboard.cards}>
            <Flex alignItems="center">
              <img src={orangeContainer} alt="blue container" width="56px" />
              <Flex flexDirection="column" pl="16px">
                <Text fontSize="12px" fontWeight="400">
                  Total No. of Upcoming Events
                </Text>
                <Text fontSize="24px" fontWeight="700">
                  {dashDetails?.totalUpcomingEvents}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex sx={styles.dashboard.cards}>
            <Flex alignItems="center">
              <img src={blueContainer} alt="blue container" width="56px" />
              <Flex flexDirection="column" pl="16px">
                <Text fontSize="12px" fontWeight="400">
                  Total No. of Ongoing Events
                </Text>
                <Text fontSize="24px" fontWeight="700">
                  {dashDetails?.totalOngoingEvents}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex sx={styles.dashboard.cards}>
            <Flex alignItems="center">
              <img src={greenContainer} alt="blue container" width="56px" />
              <Flex flexDirection="column" pl="16px">
                <Text fontSize="12px" fontWeight="400">
                  Total No. of Past Events
                </Text>
                <Text fontSize="24px" fontWeight="700">
                  {dashDetails?.totalPastEvents}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Dashboard;
