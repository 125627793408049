import * as yup from "yup";

export const signInSchema = yup.object().shape({
  email: yup.string().email("Wrong email format").required("Email is required"),
  password: yup.string().required("You must enter password"),
});

export const addUserSchema = yup.object().shape({
  firstName: yup.string().required("Enter User First Name"),
  lastName: yup.string().required("Enter User Last Name"),
  email: yup.string().email("Wrong email format").required("Email is required"),
  role: yup
    .array()
    .min(1, "Please select at least one role")
    .required("At least 1 role is Required"),
});

export const addmembershipFormSchema = yup.object().shape({
  memberShipCode: yup.string().required("Enter Membership Code"),
  firstName: yup.string().required("Enter First Name"),
  lastName: yup.string().required("Enter Last Name"),
  // email: yup.string().email("Wrong email format").required("Email is required"),
  membershipType: yup.string().required("Select Membership Type"),
  // dateJoined: yup.string().required("Enter Date Joined"),
});

export const addHotelFormSchema = yup.object().shape({
  hotelName: yup.string().required("Enter Hotel Name"),
  location: yup.string().required("Enter Location"),
  contact: yup
    .string()
    .matches(/^[0-9]+$/, "Contact must contain only numbers")
    .required("Enter your phone number"),
  hotelType: yup.string().required("Select hotel Type"),
  roomType: yup.string().required("Enter room Type"),
  amount: yup.string().required("Enter amount"),
});

export const addMembershipTypeFormSchema = yup.object().shape({
  membershipTypeName: yup.string().required("Enter Membership Type Name"),
  earlyBirdAmount: yup.string().required("Enter amount"),
  midBirdAmount: yup.string().required("Enter amount"),
  lateBirdAmount: yup.string().required("Enter amount"),
  attendanceType: yup.string().required("Enter Attendance Type"),
});

export const eventRegFormSchema = yup.object().shape({
  firstName: yup.string().required("Enter First Name"),
  lastName: yup.string().required("Enter Last Name"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Contact must contain only numbers")
    .required("Enter your phone number"),
  email: yup.string().email("Wrong email format").required("Email is required"),
  photo: yup.mixed().required("Upload Photo is required"),
  // hotel: yup.string().required("SelectThe Hotel"),
  // amount: yup.string().required("Enter amount"),
});

export const addEventSchema = yup.object().shape({
  name: yup.string().required("Enter Event Name"),
  eventType: yup.string().required("Enter Event Type"),
  startDate: yup.string().required("Enter Start Date"),
  endDate: yup.string().required("Enter End Date"),
  information: yup.string().required("Enter Description"),
  location: yup.string().required("Enter Location"),
  eventTime: yup.string().required("Enter Event Time"),
  image: yup.mixed(),
});

export const addLogoSchema = yup.object().shape({
  membershipPortalName: yup.string().required("Enter Membership Portal Name"),
  dateCreated: yup.string().required("Enter Date Created"),
  note: yup.string(),
  uploadLogo: yup.mixed().required("Upload Logo"),
  uploadSignature: yup.mixed().required("Upload Signature"),
});

export const addEventSpeakerSchema = yup.object().shape({
  firstName: yup.string().required("Enter  First Name"),
  lastName: yup.string().required("Enter Last Name"),
  biography: yup.string().required("Enter Biography"),
  topic: yup.string().required("Enter Topic"),
  event: yup.string().required("Enter Event Name"),
  photo: yup.mixed().required("Upload a Photo"),
});

export const editEventSpeakerSchema = yup.object().shape({
  photo: yup.mixed().required("Upload a Photo"),
});

export const feedbackFormSchema = yup.object().shape({
  feedback: yup.string().required("Enter your Feedback"),
  rating: yup
    .number()
    .required("Enter the rating for the feedback")
    .integer("Rating must be an integer")
    .min(1, "Rating must be at least 1")
    .max(5, "Rating must be at most 5"),
  suggestion: yup
    .string()
    .required("Enter the your suggestion for the feedback"),
  // eventId: yup.string().required("Select the event you want to give feedback"),
});

export const reportsSchema = yup.object().shape({
  eventName: yup.string().required("Enter Event Name"),
});
export const verifyPaymentSchema = yup.object().shape({
  referenceNo: yup.string().required("Enter reference number"),
});


export const bulkUploadMemberSchema = yup.object().shape({
  file: yup
    .mixed()
    .required("Upload File is required")
    .test("fileType", "Unsupported file format. Only csv, and xlsx files are allowed.", (value) => {
      if (!value) return true; 
      const supportedFormats = [ "text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      return supportedFormats.includes(value.type);
    }),
});

export const getTagSchema = yup.object().shape({
  reference: yup.string().required("Enter reference number"),
});