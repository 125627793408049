import React from 'react';
import { Box, Flex, Text,  Button,  Stack, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { BsBoxArrowLeft } from "react-icons/bs";



const ProfileContent = ({ tableProps }) => {
    // const profile = useSelector(state => state.profile)
    // console.log(profile);


    const navigate = useNavigate();
    return (
        <>
            <Box bg={'#FFFFFF'} py={'4px'} height={"100vh"}>
                <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} py={'20px'} px={'20px'}>
                    {/* <GoArrowLeft style={{ width: '24px', height: '24px' }} /> */}

                    <Text fontSize={'18px'} fontWeight={'400'}  >Profile Settings</Text>
                    <Box>
                        <Flex >
                            <Stack direction='row' spacing={14}>
                                <Button onClick={() => navigate("/change-password")} bg={"#61CE70"} color={"white"} _hover={"#61CE70"}>
                                    Change Password
                                </Button>
                                {/* {isToggle && <ChangePassword />} */}

                                <Button leftIcon={<BsBoxArrowLeft />} outlineColor={"#61CE70"} bg={"transparent"} color={"#61CE70"} _hover={"transparent"}>
                                    Logout
                                </Button>
                            </Stack>
                        </Flex>

                    </Box>
                </Flex>

                <Flex px={'20px'} pt={'20px'} gap={'50px'} flexDirection={['column', 'Column', 'Column', 'row']} justifyContent={['center', 'center', 'center', 'flex-start']} alignItems={['center', 'center', 'center', 'flex-start']} >

                    <Flex flexDirection={'column'} width={['100%', '80%', '80%']}  >
                        <Flex justifyContent={['center', 'flex-start', 'space-between']} alignItems={'center'} fontSize={'16px'} flexDirection={['column', 'row', 'row']} gap={['30px', null, null]}>
                            <Flex flexDirection={'column'} justifyContent={['center', 'flex-start', 'flex-start']} alignItems={['center', 'flex-start', 'flex-start']} width={['70%']} >
                                <FormControl>
                                    <FormLabel>First name</FormLabel>
                                    <Input placeholder='Rufus' border={"1px solid transparent"} background={"#F3F3F3"} />
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={'column'} justifyContent={['center', 'flex-start', 'flex-start']} alignItems={['center', 'flex-start', 'flex-start']} width={['70%']} >
                                <FormControl >
                                    <FormLabel>Last name</FormLabel>
                                    <Input placeholder='Giwa' border={"1px solid transparent"} background={"#F3F3F3"} />
                                </FormControl>
                            </Flex>

                        </Flex>
                        <Box my={'30px'}></Box>
                        <Flex justifyContent={['center', 'flex-start', 'space-between']} alignItems={'center'} fontSize={'16px'} flexDirection={['column', 'row', 'row']} gap={['30px', null, null]}>
                            <Flex flexDirection={'column'} justifyContent={['center', 'flex-start', 'flex-start']} alignItems={['center', 'flex-start', 'flex-start']} width={['100%']} >
                                <FormControl >
                                    <FormLabel>Email</FormLabel>
                                    <Input type='email' placeholder='rufusgiwa@gmail.com' border={"1px solid transparent"} background={"#F3F3F3"} />
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={'column'} justifyContent={['center', 'flex-start', 'flex-start']} alignItems={['center', 'flex-start', 'flex-start']} width={['100%']}  >
                                <FormControl >
                                    <FormLabel>Phone Number</FormLabel>
                                    <Input type='number' placeholder='+2347045678902' border={"1px solid transparent"} background={"#F3F3F3"} />
                                </FormControl>
                            </Flex>
                            {/* <Flex flexDirection={'column'} justifyContent={['center', 'flex-start', 'flex-start']} alignItems={['center', 'flex-start', 'flex-start']} width={['100%', '15%', '15%', '20%', '20%']} >
                                <Text color={'#515151'}>Department</Text>
                                <Button mt={4} border={'1px solid #F0F0F0'} bg={'#F9FAFC'} _hover={{ backgroundColor: '#F9FAFC' }} color={'#515151'} fontWeight={'500'} borderRadius={'4px'} >Technology</Button>
                            </Flex> */}

                        </Flex>

                    </Flex>
                </Flex>

            </Box >
        </>
    )
}

export default ProfileContent;