import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";

export const handleDeactivateMembershipType = async (item) => {
  try {
    if (item.isActive) {
      await httpRequest(
        `${BASE_URL}/MembershipType/deactivate/${item.id}`,
        "post"
      );
    } else {
      await httpRequest(
        `${BASE_URL}/MembershipType/activate/${item.id}`,
        "post"
      );
    }
  } catch (error) {
    return "An Error Occured";
  }
};
