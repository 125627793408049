import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Flex, Text, Box, Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import "../Assets/css/font.css";

function base64ToBlob(base64, mime) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
}

function base64ToImageURL(dataURL) {
  const [metadata, base64] = dataURL.split(",");
  const mime = metadata.match(/data:([^;]+);base64/)[1];
  const blob = base64ToBlob(base64, mime);
  return URL.createObjectURL(blob);
}

function splitIntoChunks(text, chunkSize) {
  const words = text.split(" ");
  const chunks = [];
  for (let i = 0; i < words.length; i += chunkSize) {
    chunks.push(words.slice(i, i + chunkSize).join(" "));
  }
  return chunks;
}

const GetEventTag = () => {
  const getEventTagData = useSelector((state) => state?.events?.getTagDetails);
  const tagData = [
    { title: "Event Name", description: getEventTagData?.eventName },
    {
      title: "Start Date",
      description:
        getEventTagData?.eventStartDate &&
        format(new Date(getEventTagData?.eventStartDate), "yyyy-MM-dd"),
    },
    {
      title: "End Date",
      description:
        getEventTagData?.eventEndDate &&
        format(new Date(getEventTagData?.eventEndDate), "yyyy-MM-dd"),
    },
  ];

  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    if (getEventTagData?.qrCodeImage) {
      const url = base64ToImageURL(getEventTagData?.qrCodeImage);
      setQrCodeUrl(url);

      // Cleanup the URL object when the component unmounts
      return () => URL.revokeObjectURL(url);
    }
  }, [getEventTagData?.qrCodeImage]);

  const handlePrintTag = () => {
    window.print();
  };

  return (
    <Box
      py={5}
      px={5}
      bgColor={"#F3FFF8"}
      id="printable-tag"
      fontFamily={"Arial"}
      fontWeight={"bold"}
    >
      <style>
        {`
        @page {
          size: 150mm 500mm;
        }
        @media print {
          @page {  size: a6 portrait;
            margin: 0mm !important;
          }
          #printable-tag {
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }
          #no-print, .modal-close-button {
            display: none;
          }
          #no-printContainer{
            justify-content: center;
          }
        }
        `}
      </style>
      <Flex
        mx={"auto"}
        mt={"-28px"}
        pt={"40px"}
        flexDirection={"column"}
        alignItems={"center"}
        bgColor={"#22944E"}
        w={["250px", "320px", "320px"]}
        h={["380px", "420px", "420px"]}
        borderRadius={"0 0 300px 300px"}
      >
        <Image
          src={getEventTagData?.logoUrl}
          alt="Logo"
          width={"158px"}
          height={"49px"}
        />
        {getEventTagData?.signatureUrl ? (
          <Image
            src={getEventTagData?.signatureUrl}
            alt="Smart Eco Logo"
            width={"250px"}
            // height={"49px"}
            mt={8}
            color={"#FFFFFF"}
            fontSize={"18px"}
            lineHeight={"14.06px"}
            position={"absolute"}
            opacity={0.1}
            top={"50%"}
          />
        ) : null}

        {/* <Image
          src={getEventTagData?.smartEcoLogo}
          alt="Smart Eco Logo"
          width={"158px"}
          height={"49px"}
          mt={8}
          color={"#FFFFFF"}
          fontSize={"18px"}
          lineHeight={"14.06px"}
        /> */}
        <Flex justifyContent={"center"} alignItems={"center"} mt={12}>
          <Image
            src={getEventTagData?.passportUrl}
            alt="Profile Pics"
            rounded={"full"}
            width={["220px", "250px", "250px"]}
            height={["220px", "250px", "250px"]}
            overflow={"hidden"}
            objectFit={"cover"}
            border={"8px solid #61CE70"}
          />
        </Flex>
      </Flex>
      <Text
        textAlign={"center"}
        mt={10}
        mb={8}
        color={"#17191C"}
        fontSize={"24px"}
        lineHeight={"25px"}
      >
        {getEventTagData?.fullName}
      </Text>
      {tagData?.map((data, index) => {
        const chunks = splitIntoChunks(data?.description || "", 5);
        const renderedDescription = chunks.join("<br />");

        return (
          <Flex
            my={5}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            key={index}
            gap={1}
          >
            <Text
              textAlign={"center"}
              textDecoration={"underline"}
              color={"#121212"}
              fontSize={"14px"}
              lineHeight={"20px"}
              opacity={0.8}
              dangerouslySetInnerHTML={{ __html: renderedDescription }}
            />
            <Text
              textAlign={"center"}
              color={"#121212"}
              fontSize={"12px"}
              lineHeight={"12.6px"}
              opacity={0.8}
            >
              {data?.title}
            </Text>
          </Flex>
        );
      })}
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
        gap={"100px"}
        id="no-printContainer"
      >
        <Text
          mt={"50px"}
          textDecoration={"underline"}
          color={"#22944E"}
          fontSize={"14px"}
          lineHeight={"14.06px"}
          cursor={"pointer"}
          id="no-print"
          onClick={handlePrintTag}
        >
          Print Tag
        </Text>
        <Image
          src={qrCodeUrl}
          alt="QR Code"
          width={"100px"}
          height={"100px"}
          mt={8}
        />
      </Flex>
    </Box>
  );
};

export default GetEventTag;
