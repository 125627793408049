import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const DetailRow = ({ label, value, type = "text", imgSrc }) => {
  return (
    <Flex gap={4}>
      <Text flexBasis={"25%"} fontWeight={"bold"} color={"#BCBCBC"}>
        {label} :
      </Text>
      {type === "text" ? (
        <Text fontWeight={"bold"} color={"#888888"}>
          {value}
        </Text>
      ) : (
        <Flex flexBasis={"60%"}>
          <Image src={imgSrc} />
        </Flex>
      )}
    </Flex>
  );
};

const LogoDetails = () => {
  const currentUpload = useSelector((state) => state.uploads?.currentUpload);

  return (
    <Box>
      <Flex
        bg={"#D6FFDB"}
        h={"4rem"}
        mx={"-6"}
        p={4}
        px={8}
        alignItems={"center"}
      >
        <Text fontSize={"1.5rem"} fontWeight={"bold"} color={"#22944E"}>
          {currentUpload?.membershipPortalName}
        </Text>
      </Flex>
      <Flex direction={"column"} gap={4} py={4} px={3}>
        <DetailRow
          label={"Date Created"}
          value={
            new Date(currentUpload?.dateCreated).toISOString().split("T")[0]
          }
        />
        <DetailRow
          label={"Logo"}
          type="image"
          imgSrc={currentUpload?.logoUrl}
        />
        <DetailRow
          label={"Signature"}
          type="image"
          imgSrc={currentUpload?.signatureUrl}
        />
      </Flex>
    </Box>
  );
};

export default LogoDetails;
