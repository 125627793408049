import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../../redux/slices/modalSlice";
import AddMemberMgt from "../AddMemberMgt";
import MemberDetailsMgt from "../MemberDetailsMgt";
import EditMemberMgt from "../EditMemberMgt";
import AddHotel from "../AddHotel";
import HotelDetails from "../HotelDetails";
import EditHotel from "../EditHotel";
import AddMembershipType from "../AddMembershipType";
import MembershipTypeDetails from "../MembershipTypeDetails";
import EventRegModalContainer from "../EventRegModalContainer";
import EventRegForm from "../EventRegForm";
import EditMembershipType from "../EditMembershipType";
import EventPayment from "../EventPayment";
import AddEvent from "../AddEvent";
import AddLogo from "../AddLogo";
import EditEvent from "../EditEvent";
import EditLogo from "../EditLogo";
import EventDetails from "../EventDetails";
import LogoDetails from "../LogoDetails";
import PrintTag from "../PrintTag";
import AddEventSpeaker from "../AddEventSpeaker";
import EditEventSpeaker from "../EditEventSpeaker";
import EventSpeakerDetails from "../EventSpeakerDetails";
import NonMemEventRegForm from "../NonMemEventRegForm";
import EventReportDetails from "../EventReportDetails";
import BulkUploadMembers from "../BulkUploadMembers";
import GetTag from "../../screens/GetTag";
import GetEventTag from "../../screens/GetEventTag";
import BulkUploadGuests from "../BulkUploadGuests";

const FormModal = () => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useDispatch();
  const formModal = useSelector((state) => state.modal.formModal);

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={formModal?.status}
        onClose={() => {
          dispatch(setFormModal({ status: false, data: null }));
        }}
        size={formModal?.size}
      >
        <ModalOverlay />
        <ModalContent
          bgColor={formModal?.type === "getEventTag" ? "#F3FFF8" : "#FFFFFF"}
        >
          {formModal?.title && (
            <ModalHeader
              borderBottom={"1px"}
              borderBottomColor={"#EFF4FA"}
              color={"#121212"}
              fontSize={"medium"}
            >
              {formModal?.title}
            </ModalHeader>
          )}

          <ModalCloseButton className="modal-close-button" />

          <ModalBody pb={6}>
            {formModal?.type === "addMember" ? (
              <AddMemberMgt />
            ) : formModal?.type === "memberDetails" ? (
              <MemberDetailsMgt />
            ) : formModal?.type === "editMember" ? (
              <EditMemberMgt />
            ) : formModal?.type === "addHotel" ? (
              <AddHotel />
            ) : formModal?.type === "hotelDetails" ? (
              <HotelDetails />
            ) : formModal?.type === "editHotel" ? (
              <EditHotel />
            ) : formModal?.type === "addMembershipType" ? (
              <AddMembershipType />
            ) : formModal?.type === "editMembershipType" ? (
              <EditMembershipType />
            ) : formModal?.type === "viewMembershipType" ? (
              <MembershipTypeDetails />
            ) : formModal?.type === "eventRegCont" ? (
              <EventRegModalContainer />
            ) : formModal?.type === "eventRegForm" ? (
              <EventRegForm />
            ) : formModal?.type === "nonMemEventRegForm" ? (
              <NonMemEventRegForm />
            ) : formModal?.type === "eventPayment" ? (
              <EventPayment />
            ) : formModal?.type === "addEvent" ? (
              <AddEvent />
            ) : formModal?.type === "editEvent" ? (
              <EditEvent />
            ) : formModal?.type === "addLogo" ? (
              <AddLogo />
            ) : formModal?.type === "editLogo" ? (
              <EditLogo />
            ) : formModal?.type === "eventDetails" ? (
              <EventDetails />
            ) : formModal?.type === "logoDetails" ? (
              <LogoDetails />
            ) : formModal?.type === "prinTag" ? (
              <PrintTag />
            ) : formModal?.type === "addSpeaker" ? (
              <AddEventSpeaker />
            ) : formModal?.type === "editSpeaker" ? (
              <EditEventSpeaker />
            ) : formModal?.type === "speakerDetails" ? (
              <EventSpeakerDetails />
            ) : formModal?.type === "eventReportDetails" ? (
              <EventReportDetails />
            ) : formModal?.type === "uploadMembers" ? (
              <BulkUploadMembers />
            ) : formModal?.type === "uploadGuests" ? (
              <BulkUploadGuests />
            ) : formModal?.type === "getTag" ? (
              <GetTag />
            ) : formModal?.type === "getEventTag" ? (
              <GetEventTag />
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormModal;
