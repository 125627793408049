import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import {setModalToggle, setFormModal} from "../redux/slices/modalSlice";
import { useDispatch } from 'react-redux';

const EventRegModal1 = () => {
    const dispatch = useDispatch();

    const handleRegisterEvent = () => {
        dispatch(setFormModal({status:true, title: "Registration Form", type:"nonMemEventRegForm"}));
    }
    
  return (
    <>
        <Flex flexDirection={"column"} alignItems={'center'} mt={10}>
            <Text color={'#121212'} fontSize={'40px'} fontWeight={'700'} lineHeight={'42px'}>Registration</Text>
            <Text py={2} color={'#7A7A7A'} fontSize={'18px'} fontWeight={'400'} lineHeight={'24px'} mt={'10px'}>
                Kindly select below if you are a member or a non-member
            </Text>
            <Flex mt={12} mb={10} justifyContent={'space-between'} alignItems={'center'} width={'full'} gap={8}>
                <Button width={"100%"} background={"#22944E"} color={"#FFFFFF"} fontSize={'16px'} fontWeight={'700'} borderRadius={'3px'}
                    _hover={{ background: " " }} 
                    onClick={() => dispatch(setModalToggle()) }
                >
                    Member
                </Button>

                <Button width={"100%"} background={"#22944E"} color={"#FFFFFF"} fontSize={'16px'} fontWeight={'700'} borderRadius={'3px'}
                    _hover={{ background: " " }} 
                    onClick={handleRegisterEvent}
                >
                   Non-Member
                </Button>
            </Flex>
        </Flex>
    </>
  )
}

export default EventRegModal1;