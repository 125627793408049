import React,{useState} from "react";
import { Form, Formik } from "formik";
import {
    Alert,AlertIcon, AlertTitle, Box, Button,Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import {verifyPaymentSchema} from "../utils/formValidationSchema";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import CustomTable from "../components/CustomTable";

const PaymentVerification = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [refData, setRefData] = useState('');
    console.log('refData',refData);

    const initialValues = {
        referenceNo:'',
    }
  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
        const response = await httpRequest(
            `${BASE_URL}/Payment/get-reference-transaction?reference=${values.referenceNo}`,
        );
        console.log('response',response);
        if(response.isSuccessful === true){
            setLoading(false);
            setError("");
            setRefData(response.data);
        }else{
            setLoading(false);
            setError(response?.response?.data?.error?.responseDescription || "Something went wrong. Please try again");
        }
    } catch (error) {
        setError(error.message || "Something went wrong. Please try again");
    }finally{
        setLoading(false);
    }
  };

// 157121453
  const tableProps = {
    data: refData,
    title: {
        name: "Name",
        email: "Email",
        amount: "Amount ",
        transactionRef: "Transaction Reference",
        status: "Status",
    },
    dataKeys: [
      "name",
      "email",
      "amount",
      "transactionRef",
      "status",
    ],
    variant:"true",
    transformRow: (item) => ({
        name: <div style={{cursor:'pointer'}} >{item?.name}</div>,
        email: <div style={{cursor:'pointer'}} >{item?.email}</div>,
        amount: <div style={{cursor:'pointer'}} >{item?.amount}</div>,
        transactionRef: <div style={{cursor:'pointer'}} >{item?.transactionRef}</div>,
        status: <div style={{cursor:'pointer'}} >{item?.status}</div>,
    }),
    
  };

  return (
    <Box bg={"#FFFFFF"} py={"20px"} px={"20px"}>
        {
        error &&(
        <Alert status="error" className="mt-3">
            <AlertIcon />
            <AlertTitle>{ error}</AlertTitle>
        </Alert>
        )
        }
        <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={verifyPaymentSchema}
        onSubmit={(values) => handleSubmit(values)}
        >
        {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
            <FormControl mt={4} isInvalid={errors.referenceNo && touched.referenceNo}
                color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='referenceNo' fontSize={"16px"}>Reference Number</FormLabel>
            <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="referenceNo"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.referenceNo}
            />
            {touched.referenceNo && errors.referenceNo && (
                <FormErrorMessage>{errors.referenceNo}</FormErrorMessage>
            )}
            </FormControl>
            <Flex my={8} justifyContent={"flex-end"} alignItems={"center"}>
                <Button
                bg={"#005E39"}
                color={"#FFFFFF"}
                px={10}
                lineHeight={"24.46px"}
                letterSpacing={"-0.25px"}
                fontSize={"16px"}
                fontWeight={"400"}
                _hover={{ bg: "" }}
                _active={{ bg: "" }}
                type="submit"
                isLoading={loading}
                loadingText="Fetching..."
                >
                Fetch
                </Button>
            </Flex>
        </Form>
        )}
        </Formik>
        {
        !error && refData && (
            <CustomTable {...tableProps} />
        )
        }
    </Box>
  );
};

export default PaymentVerification;
