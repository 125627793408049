import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import {Button, Flex, Text, Box } from '@chakra-ui/react';

const MemberDetailsMgt = () => {

    const MemberDetails = useSelector((state) => state?.membershipMgt?.memberDetails);
    // console.log("MemberDetails", MemberDetails);
    
    const historyData = [
        {title:"Date Joined:", discription:MemberDetails?.dateJoined && format(new Date(MemberDetails?.dateJoined), 'yyyy-MM-dd')},
        {title:"Membership Code:", discription:MemberDetails?.memberShipCode},
        {title:"Email Address:", discription:MemberDetails?.email},
       
    ]

  return (
    <>
    <Box minH={'80vh'}>
        <Flex my={4} bg={'#D6FFDB'} color={'#22944E'} justifyContent={'space-between'} alignItems={'center'} py={[3, null, null]} mx={-6} >
        <Flex flexDirection={'column'} pl={6}  >
            <Flex gap={3} alignItems={'center'} >
            <Text fontSize={['18px', '20px', '24px']} fontWeight={'600'} maxW={'700px'} lineHeight={'16px'} >{MemberDetails?.firstName + " " + MemberDetails?.lastName} </Text>
            <Button size={'xs'} bg={'#ADC4FF'} color={'#356EFF'} borderRadius={'4px'} fontSize={'16px'} lineHeight={'20px'}
            _hover={{backgroundColor:'#E1E9FF'}}
            >Active</Button>
            </Flex>
        </Flex>
        </Flex>

        {
        historyData?.map((data, index) =>(
        <Flex fontSize={'16px'} fontWeight={'500'} opacity={'0.5'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}>
            <Text width={'35%'} color={'#7A7A7A'}  >{data?.title}</Text>
            <Text  width={'65%'} color={'#121212'} >{data?.discription}</Text>
        </Flex>
        ))
        }
    </Box>
    </>
  )
}

export default MemberDetailsMgt;