import { Flex } from "@chakra-ui/react";
import React from "react";

const UploadButton = () => {
  return (
    <Flex
      bg={"#F3F3F3"}
      border={"1px dotted #61CE70"}
      color={"#61CE70"}
      borderRadius={"md"}
      justify={"center"}
      alignItems={"center"}
      h={"4rem"}
      w={"full"}
      cursor={"pointer"}
    >
      <p>Browse files</p>
    </Flex>
  );
};

export default UploadButton;
