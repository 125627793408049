import React from "react";
import { useSelector } from "react-redux";
import { Flex, Text, Box } from "@chakra-ui/react";

const MembershipTypeDetails = () => {
  const MemberTypeDetails = useSelector(
    (state) => state?.membershipType?.membershipTypeDetails
  );
  // console.log(MemberTypeDetails);

  const formatAmount = (amount) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };

  const historyData = [
    // { title: "Description:", description: MemberTypeDetails.description },
    {
      title: "Early Bird:",
      description: formatAmount(MemberTypeDetails?.earlyBirdAmount),
    },
    {
      title: "Mid Bird:",
      description: formatAmount(MemberTypeDetails?.midBirdAmount),
    },
    {
      title: "Late Bird:",
      description: formatAmount(MemberTypeDetails?.lateBirdAmount),
    },
    { title: "Events:", description: MemberTypeDetails?.event?.name },
    {
      title: "Attendance Type:",
      description: MemberTypeDetails?.attendanceType,
    },
  ];

  return (
    <>
      <Box minH={"40vh"}>
        <Flex
          my={4}
          bg={"#D6FFDB"}
          color={"#22944E"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={[3, null, null]}
          mx={-6}
        >
          <Flex flexDirection={"column"} pl={6}>
            <Flex gap={3} alignItems={"center"}>
              <Text
                fontSize={["18px", "20px", "24px"]}
                fontWeight={"600"}
                maxW={"700px"}
                lineHeight={"16px"}
              >
                Member
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {historyData?.map((data, index) => (
          <Flex
            fontSize={"16px"}
            fontWeight={"500"}
            opacity={"0.5"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            mb={4}
            key={index}
            gap={[6, 3, 3]}
          >
            <Text width={"35%"} color={"#7A7A7A"}>
              {data.title}
            </Text>
            <Text width={"65%"} color={"#121212"}>
              {data.description}
            </Text>
          </Flex>
        ))}
      </Box>
    </>
  );
};

export default MembershipTypeDetails;
