import React, {useState } from 'react';
import { Form, Formik } from "formik";
import { Button, Text, FormControl, FormErrorMessage, FormLabel,Flex, Input, Alert,AlertIcon, AlertTitle  } from "@chakra-ui/react";
import { useDispatch} from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {bulkUploadMembers, fetchUploadedMembers} from "../redux/slices/membershipMgtSlice";
import {bulkUploadMemberSchema} from "../utils/formValidationSchema";

const BulkUploadMembers = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const initialValues = {
        file: '',
    };

    const handleSubmit = async (values) => {
        // console.log(values);
        setLoading(true);
        setError("");
        try {
            const formData = {
                file: values?.file,
            };

            const response = await dispatch(bulkUploadMembers(formData));
            // console.log("response", response);

            if (response?.payload?.isSuccessful === true) {
                setLoading(false);
                dispatch(setFormModal({ status: false }));
                await dispatch(fetchUploadedMembers());
            }else{
                setLoading(false);
                setError(response?.payload?.errors?.file?.map((errors) => errors) || response?.payload?.errors || "Failed to upload members. Please try again");
            }
            
        } catch (error) {
            setError(error?.message || "Something went wrong. Please try again");
        }finally{
            setLoading(false);
        }

    }

  return (
    <>
        {
          error &&(
          <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
          </Alert>
          )
        }
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={bulkUploadMemberSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
                <Form>
                    <FormControl mt={4} isInvalid={errors.file && touched.file}
                        color="#121212" fontFamily={'inter'}>
                        <FormLabel htmlFor='file' fontSize={"16px"}>
                            Upload File
                        </FormLabel>
                        <Input
                            type='file'
                            name="file"
                            id="file"
                            placeholder='accepts ".csv" files'
                            fontSize={"small"}
                            bg={'#F3F3F3'}
                            focusBorderColor="#ffffff"
                            borderRadius={'0'}
                            borderColor={"#FFFFFF"}
                            _placeholder={{ color: "#B4B1B1" }}
                            _hover={{ borderColor: "#FFFFFF" }}
                            onBlur={handleBlur}
                            onChange={(event) => {
                                setFieldValue('file', event.currentTarget.files[0]);
                            }}
                            accept=".csv, .xlsx" 
                        />
                        <Text color={'#D11A2A'}> accepts ".csv, .xlsx" files</Text>
                        {touched.file && errors.file && (
                            <FormErrorMessage>{errors.file}</FormErrorMessage>
                        )}
                    </FormControl>

                    <Flex justifyContent={'Flex-end'} alignItems={"center"} gap={5}>
                      <Button
                          mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                          width={"118px"}
                          bg={"brand.primary"}
                          _hover={{ background: " " }}
                          color={"#FFFFFF"}
                          type="submit"
                          isLoading={loading}
                          loadingText="Saving ..."
                      >
                          Upload
                      </Button>
                     
                    </Flex>
                </Form>
            )}
        </Formik>
      </>
  )
}

export default BulkUploadMembers;