import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Select,
  Image,
} from "@chakra-ui/react";
import CustomTable from "../components/CustomTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";
import { fetchAllUploads, setCurrentUpload } from "../redux/slices/uploadSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";

const UploadManagement = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchAllUploads());
  }, [isLoading, dispatch]);
  const uploads = useSelector((state) => state.uploads.data);
  console.log("uploads", uploads);

  const handleAdd = () => {
    dispatch(
      setFormModal({
        status: true,
        title: "Add New Logo",
        type: "addLogo",
      })
    );
  };

  const handleDelete = async (item) => {
    setIsLoading(false);
    try {
      await httpRequest(
        `${BASE_URL}/UploadManagement/DeleteUpload/${item.id}`,
        "delete"
      );
      setIsLoading(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleEdit = async (item) => {
    dispatch(setCurrentUpload(item));
    dispatch(
      setFormModal({ status: true, type: "editLogo", title: "Edit Logo" })
    );
  };

  const showLogoDetails = (item) => {
    dispatch(setCurrentUpload(item));
    dispatch(
      setFormModal({ status: true, type: "logoDetails", title: "Logo Details" })
    );
  };

  const tableProps = {
    data: uploads,
    title: {
      membershipPortalName: "Membership Portal Name",
      logoUrl: "Logo",
      signatureUrl: "Signature",
      action: "Action",
    },
    dataKeys: ["membershipPortalName", "logoUrl", "signatureUrl", "action"],
    variant: "true",
    transformRow: (item) => ({
      membershipPortalName: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => showLogoDetails(item)}
        >
          {item?.membershipPortalName}
        </div>
      ),
      logoUrl: (
        <div
          style={{ cursor: "pointer", height: "3rem" }}
          onClick={() => showLogoDetails(item)}
        >
          <Image src={item?.logoUrl} height={"100%"} />
        </div>
      ),
      signatureUrl: (
        <div
          style={{ cursor: "pointer", height: "3rem" }}
          onClick={() => showLogoDetails(item)}
        >
          <Image src={item?.signatureUrl} height={"100%"} />
        </div>
      ),
      Action: (
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <>
      <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"}>
        <Flex
          flexDirection={["column", "row", "row"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
          borderBottom={"1px solid #B4B1B1"}
          py={"20px"}
          px={"20px"}
          mb={5}
        >
          <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
            Upload Management
          </Text>
          <Flex
            alignItems={"center"}
            gap={5}
            flexDirection={["column", "row", "row"]}
          >
            <Button
              borderRadius={"3px"}
              bg={"brand.primary"}
              leftIcon={<PiPlusBold />}
              color="white"
              onClick={handleAdd}
              px={16}
              _hover={{ backgroundColor: "" }}
            >
              Add New Logo
            </Button>
            <Select
              bg={"#FFFFFF"}
              focusBorderColor="0.5px solid brand.primary"
              _hover={{ border: "" }}
              border={"1px solid #22944E"}
              _placeholder={{ color: "#8A8C8E" }}
              color={"brand.primary"}
              borderRadius={"0"}
            >
              <option value="Filter by Ref Code">Filter by Code</option>
              <option value="Filter by Name">Filter by Name</option>
              <option value="Filter by Status">Filter by Status</option>
            </Select>
          </Flex>
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
    </>
  );
};

export default UploadManagement;
