import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  //   Input,
  Select,
  Text,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { genReports } from "../../redux/slices/reportsSlice";
import { reportsSchema } from "../../utils/formValidationSchema";

const ReportPanel = () => {
  const dispatch = useDispatch();

  const initialValues = {
    eventName: "",
    startDate: "",
    endDate: "",
    membershipType: "",
    eventType: "",
    // attendanceType: "",
    // amount:"",
  };

  const allEvents = useSelector((state) => state.eventRegistration?.allEvents);
  //   const membershipType = useSelector(
  //     (state) => state.membershipType?.membershipType
  //   );

  const handleSubmit = async (values) => {
    try {
      const formData = {
        eventName: values.eventName,
        eventType: values.eventType,
        startDate: values.startDate,
        endDate: values.endDate,
        membershipType: values.membershipType,
        // attendanceType: values.attendanceType,
        // amount: values.amount,
      };

      const response = await dispatch(genReports(formData));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Text color={"#5CBF14"} fontSize={"16px"}>
        Select any option below to generate report
      </Text>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={reportsSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Grid
            as={Form}
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={4}
            py={4}
          >
            <GridItem>
              <FormControl isInvalid={errors.eventName && touched.eventName}>
                <FormLabel htmlFor="eventName">Event Name</FormLabel>
                <Select
                  placeholder="Select name"
                  bg={"#FAFAFA"}
                  name="eventName"
                  border={"0.5px solid #8A8C8E"}
                  _placeholder={{ color: "#8A8C8E" }}
                  focusBorderColor="0.5px solid #8A8C8E"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.eventName}
                >
                  {allEvents &&
                    allEvents.map((event) => (
                      <option key={event?.id} value={event?.name}>
                        {event?.name}
                      </option>
                    ))}
                </Select>
                {touched.eventName && errors.eventName && (
                  <FormErrorMessage>{errors.eventName}</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            {/* <GridItem>
                            <FormControl>
                                <FormLabel htmlFor='startDate'>Start Date</FormLabel>
                                <Input type='date' bg={'#FAFAFA'} name='startDate' border={'0.5px solid #8A8C8E'}
                                    _placeholder={{ color: '#8A8C8E' }} focusBorderColor='0.5px solid #8A8C8E' color={'#8A8C8E'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.startDate}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel htmlFor='endDate'>End Date</FormLabel>
                                <Input type='Date' bg={'#FAFAFA'} border={'0.5px solid #8A8C8E'} name='endDate' _placeholder={{ color: '#8A8C8E' }} focusBorderColor='0.5px solid #8A8C8E' color={'#8A8C8E'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.endDate}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel htmlFor='membershipType'>Membership Type</FormLabel>
                                <Select placeholder='Select type' bg={'#FAFAFA'}
                                    border={'0.5px solid #8A8C8E'}
                                    name='membershipType'
                                    _placeholder={{ color: '#8A8C8E' }} focusBorderColor='0.5px solid #8A8C8E'
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values?.membershipType}
                                    >
                                    { membershipType &&
                                        membershipType.map((member) => (
                                            <option key={member?.id} value={member?.name}>{member?.name}</option>
                                        ))
                                    }
                                   
                                </Select>

                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel htmlFor='eventType'>Event Type</FormLabel>
                                <Select placeholder='Select type' bg={'#FAFAFA'}
                                    border={'0.5px solid #8A8C8E'}
                                    name='eventType'
                                    _placeholder={{ color: '#8A8C8E' }} focusBorderColor='0.5px solid #8A8C8E'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.eventType}
                                    >
                                     {  allEvents &&
                                        allEvents.map((event) => (
                                            <option key={event?.id} value={event?.eventType}>{event?.eventType}</option>
                                        ))
                                    }
                                </Select>

                            </FormControl>
                        </GridItem> */}
            {/* <GridItem>
                            <FormControl>
                                <FormLabel htmlFor='membershipType'>Attendance Type</FormLabel>
                                <Select placeholder='Select type' bg={'#FAFAFA'}
                                    border={'0.5px solid #8A8C8E'}
                                    name='attendanceType'
                                    _placeholder={{ color: '#8A8C8E' }} focusBorderColor='0.5px solid #8A8C8E'
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values?.membershipType}
                                    >
                                    <option value='physical'>Physical</option>
                                    <option value='virtual'>Virtual</option>
                                    
                                </Select>

                            </FormControl>
                        </GridItem> */}
            {/* <GridItem>
                            <FormControl isInvalid={errors.amount && touched.amount}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='amount' fontSize={"16px"}> Amount</FormLabel>
                                <Input
                                    type="number"
                                    placeholder="Enter Amount"
                                    fontSize={"small"}
                                    bg={'#FAFAFA'}
                                    name="amount"
                                    focusBorderColor='0.5px solid #8A8C8E'
                                    border={'0.5px solid #8A8C8E'}
                                    _placeholder={{ color: '#8A8C8E' }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.amount}
                                />
                                {touched.amount && errors.amount && (
                                    <FormErrorMessage>{errors.amount}</FormErrorMessage>
                                )}
                            </FormControl>
                        </GridItem> */}
            <GridItem>
              <FormControl>
                <FormLabel color={"transparent"}>.</FormLabel>
                <Button
                  width={"100%"}
                  type="submit"
                  color={"#FFFFFF"}
                  fontWeight={"500"}
                  lineHeight={"-0.25"}
                  bg={"brand.primary"}
                  borderRadius={"3px"}
                  _hover={{ backgroundColor: "#005E39" }}
                >
                  Generate Report
                </Button>
              </FormControl>
            </GridItem>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default ReportPanel;
