import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  membershipType: [],
  loading: false,
  error: null,
  editType: "",
  membershipTypeDetails: "",
  events: [],
};

export const fetchMembershipType = createAsyncThunk(
  "MembershipType/fetchMembershipType",
  async () => {
    return httpRequest(`${BASE_URL}/MembershipType/get-all-membershipType`);
  }
);

export const fetchEvents = createAsyncThunk(
  "EventManagement/fetchEvents",
  async () => {
    return httpRequest(`${BASE_URL}/EventManagement/get-all-events`);
  }
);

export const createMembershipType = createAsyncThunk(
  "membershipType/createMembershipType",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/MembershipType/create-membershipType`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateMembershipType = createAsyncThunk(
  "membershipType/updateMembershipType",
  async (arg, id, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/MembershipType/update-membershipType?memberlId=${id}`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const membershipTypeSlice = createSlice({
  name: "membershipType",
  initialState,

  reducers: {
    setMembershipTypeDetails: (state, { payload }) => {
      state.membershipTypeDetails = payload;
    },
    setEditType: (state, { payload }) => {
      state.editType = payload;
    },
  },

  extraReducers: (builder) => {
    // Get all Membership Types
    builder.addCase(fetchMembershipType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMembershipType.fulfilled, (state, action) => {
      state.loading = false;
      state.membershipType = action?.payload?.data;
    });
    builder.addCase(fetchMembershipType.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });

    // Create MembershipType
    builder.addCase(createMembershipType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMembershipType.fulfilled, (state, action) => {
      state.loading = false;
      state.members.push(action?.payload?.data);
      state.error = null;
    });
    builder.addCase(createMembershipType.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });

    // Edit MembershipType
    // builder.addCase(updateMembershipType.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(updateMembershipType.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.members = state.members;
    //   state.error = null;
    // });
    // builder.addCase(updateMembershipType.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action?.error?.message;
    // });

    // Get all events
    builder.addCase(fetchEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action?.payload?.data;
    });
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });
  },
});

export const { setMembershipTypeDetails, setEditType } =
  membershipTypeSlice.actions;
export default membershipTypeSlice.reducer;
