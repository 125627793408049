import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";

const initialState = {
    reports: [],
    loading: false,
    error: null,
};


export const genReports = createAsyncThunk("reports/genReports", async (arg, { rejectWithValue }) => {
    try {
     const response = await httpRequest(`${BASE_URL}/Report/FilterReport`,
      "post",
      arg ,
      null,
      "multipart/form-data",
      );
    //   console.log("genReports", response);
     if (response?.response?.data) {
         throw response;
     }
      return response;
     
    } catch (error) {
     console.log("error: ",error?.response?.data);
     return rejectWithValue(error?.response?.data);
    }
 });



const reportsSlice = createSlice({
    name: "reports",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(genReports.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(genReports.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.reports = payload;
            state.error = null;
        });

        builder.addCase(genReports.rejected, (state, {payload}) => {
            state.loading = false;
            state.reports = [];
            state.error = payload;
        });
    },
});


// export const {}= reportsSlice.actions;
export default reportsSlice.reducer;