import React,{useState, useEffect} from 'react';
import { Box, Flex, Text, Alert,AlertIcon, AlertTitle, } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

const FeedBack = () => {
  const [error, setError] = useState("");
  const [feedback, setFeedback] = useState("");
  const allFeedBack = async () =>{
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/Feedback/get-all-feedbacks`,
      );
      // console.log("response", response.data);

      if(!response?.isSuccessful === true){
        setError(response?.message || "Something went wrong. Please try again");
      }else{
        setFeedback(response?.data);
      }
      
    } catch (error) {
      setError(error?.message || "Something went wrong. Please try again");
    }

  };

  useEffect(() => {
    allFeedBack();
  }, []);

  const tableProps = {
    data: feedback,
    title: {
      event: "Event",
      feedback:"Feedback",
      suggestion:"Suggestion",
      rating:"rating",
    
    },
    dataKeys: [
      "event",
      "feedback",
      "suggestion",
      "rating",
      
    ],
    variant:"true",
    transformRow: (item) => ({
      event: <div style={{cursor:'pointer'}} 
        >{ item?.event?.name}</div>,
        feedback: <div style={{cursor:'pointer'}} 
        >{item?.feedback}</div>,
        suggestion: <div style={{cursor:'pointer'}} 
        >{item?.suggestion}</div>,
        rating: <div style={{cursor:'pointer'}} 
        >{item?.rating}</div>,
    }),
  
};

  return (
    <>
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} >
      <Flex flexDirection={['column', 'row', 'row']} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} py={'20px'} px={'20px'} mb={5}>
        <Text fontSize={'18px'} fontWeight={'400'}  fontFamily={'Roboto'}>Feedback</Text>
      </Flex>
      {
        error &&(
        <Alert status="error" className="mt-3">
            <AlertIcon />
            <AlertTitle>{ error}</AlertTitle>
        </Alert>
        )
        }
      <CustomTable {...tableProps} />
    </Box>
    </>
  )
}

export default FeedBack;