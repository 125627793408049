import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";

const initialState = {
  data: [],
  loading: false,
  error: null,
  currentUpload: null,
};

export const fetchAllUploads = createAsyncThunk(
  "uploads/fetchAllUploads",
  async () => {
    const response = httpRequest(
      `${BASE_URL}/UploadManagement/get-all-uploads`
    );
    return response;
  }
);

const uploadSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    addUpload: (state, { payload }) => {
      state.data.push(payload.data);
    },
    setCurrentUpload: (state, { payload }) => {
      state.currentUpload = payload;
    },
    editUpload: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUploads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUploads.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    });
    builder.addCase(fetchAllUploads.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.responseDescription;
    });
  },
});

export const { addUpload, setCurrentUpload, editUpload } = uploadSlice.actions;
export default uploadSlice.reducer;
