import { Route, Routes } from "react-router-dom";
import Authenticate from "./components/Authenticate";
import { extendTheme, ChakraBaseProvider } from "@chakra-ui/react";
import Login from "./screens/Login";
import Reports from "./screens/Reports";
import Profile from "./screens/Profile";
import ActivateUser from "./screens/ActivateUser";
import ResetPassword from "./screens/ResetPassword";
import ForgotPassword from "./screens/ForgotPassword";
import Dashboard from "./screens/Dashboard";
import MembershipType from "./screens/MembershipType";
import EventManagement from "./screens/EventManagement";
import UploadManagement from "./screens/UploadManagement";
import MembershipManagement from "./screens/MembershipManagement";
import HotelAccommodation from "./screens/HotelAccommodation";
import FormModal from "./components/modals/FormModal";
import ChangePassword from "./components/layout/ChangePassword";
import ConfirmModal from "./components/modals/ConfirmModal";
import AllEvents from "./screens/AllEvents";
import EventDetails from "./screens/EventDetails";
import PaymentReturn from "./components/PaymentReturn";
import EventSpeaker from "./screens/EventSpeaker";
import FeedBack from "./screens/FeedBack";
import FeedBackForm from "./screens/FeedBackForm";
import PaymentVerification from "./screens/PaymentVerification";
import CustomToast from "./components/layout/CustomToast";
import MarkAttendance from "./screens/MarkAttendance";

const theme = extendTheme({
  fonts: {
    heading: `'DaxlinePro', sans-serif`,
    // body: `'DaxlinePro', sans-serif`,
    body: `'Roboto', sans-serif`,
    roboto: `'Roboto', sans-serif`,
    inter: ` 'Inter', sans-serif`,
  },
  colors: {
    brand: {
      primary: "#22944E",
    },
  },
  Table: {
    variants: {
      striped: {
        tbody: {
          "tr:nth-child(odd)": {
            bg: "#F5FFFB",
          },
        },
      },
    },
  },
});

const App = () => {
  return (
    <ChakraBaseProvider theme={theme}>
      <FormModal />
      <ConfirmModal />
      <CustomToast />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/activate-account/:userId" element={<ActivateUser />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/all-events" element={<AllEvents />} />
        <Route path="/event-details/:id" element={<EventDetails />} />
        <Route path="/return" element={<PaymentReturn />} />
        <Route path="/feedback-form/:id" element={<FeedBackForm />} />
        {/* <Route path="/mark-attendance" element={<MarkAttendance />} /> */}

        <Route element={<Authenticate />}>
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="membership-type" element={<MembershipType />} />
          <Route exact path="event-management" element={<EventManagement />} />
          <Route path="/event-speaker" element={<EventSpeaker />} />
          <Route path="/upload-management" element={<UploadManagement />} />
          <Route
            path="/membership-management"
            element={<MembershipManagement />}
          />
          <Route path="/hotel-accommodation" element={<HotelAccommodation />} />
          <Route path="/report" element={<Reports />} />
          <Route
            path="/payment-verification"
            element={<PaymentVerification />}
          />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/mark-attendance" element={<MarkAttendance />} />
        </Route>
      </Routes>
    </ChakraBaseProvider>
  );
};

export default App;
