import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Flex,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
// import { useNavigate } from "react-router-dom";

const EventPayment = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const eventPaymentDetails = useSelector(
    (state) => state?.eventRegistration?.eventPaymentDetails
  );
  // console.log("eventPaymentDetails", eventPaymentDetails);

  const historyData = [
    { title: "Email:", discription: eventPaymentDetails.email },
    // {title:"Event Amount:", discription:eventPaymentDetails.eventAmount },
    // {title:"Hotel Amount:", discription:eventPaymentDetails.hotelAmount},
    // {title:"Registration FormId:", discription:eventPaymentDetails.registrationFormId},
    { title: "Total Amount:", discription: eventPaymentDetails.totalAmount },
  ];

  const handlePayment = async () => {
    setError("");
    setLoading(true);
    try {
      const response = await httpRequest(`${BASE_URL}/Payment`, "post", {
        email: eventPaymentDetails.email,
        // eventAmount: eventPaymentDetails.eventAmount,
        // hotelAmount: eventPaymentDetails.hotelAmount,
        registrationFormId: eventPaymentDetails.registrationFormId,
        totalAmount: eventPaymentDetails.totalAmount,
      });
      // console.log("response payment", response);
      const { authorizationUrl } = response.data;

      if (response.isSuccessful === true) {
        setLoading(false);
        localStorage.removeItem("paymentDetails");
        localStorage.setItem("paymentDetails", JSON.stringify(response.data));
        window.location.href = authorizationUrl;
        // navigate("/return");
      } else {
        setLoading(false);
        setError(
          response?.response?.data?.errors?.map((errors) => errors) ||
            response?.response?.data?.responseDescription ||
            "Something went wrong. Please try again"
        );
      }
    } catch (error) {
      setError(error?.message || "Something went wrong. Please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{errorsArray.join(", ")}</AlertTitle>
        </Alert>
      )}
      {historyData?.map((data, index) => (
        <Flex
          fontSize={"16px"}
          fontWeight={"500"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          mb={4}
          key={index}
          gap={[6, 3, 3]}
        >
          <Text width={"35%"} color={"#7A7A7A"}>
            {data.title}
          </Text>
          <Text width={"65%"} color={"#121212"}>
            {data.discription}
          </Text>
        </Flex>
      ))}
      <Button
        mt={10}
        width={"100%"}
        background={"#22944E"}
        _hover={{ background: " " }}
        color={"#FFFFFF"}
        onClick={handlePayment}
        isLoading={loading}
        loadingText="Loading ..."
      >
        Make Payment
      </Button>
    </>
  );
};

export default EventPayment;
