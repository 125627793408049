import {
  Box,
  Circle,
  Flex,
  Image,
  IconButton,
  useDisclosure,
  // InputGroup, Input, InputLeftElement,
} from "@chakra-ui/react";
import React from "react";
// import { BiSearch } from "react-icons/bi";
import { 
  AiOutlineBell, 
  AiOutlineMenu } from "react-icons/ai";
import MobileSidebar from "./MobileSidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeUser } from "../../redux/slices/authSlice";
import ncsLogo from "../../Assets/Images/ncs-logo.png";

const MobileHeader = ({show = true, logo= false,}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const signOut = () => {
    dispatch(removeUser())
    navigate("/")
  }

  return (
    <Flex
      h="5rem"
      bg="white"
      px={4}
      alignItems={"center"}
      position={"sticky"}
      top={0}
      zIndex={200}
      boxShadow={"0px 2px 10px 0px rgba(198, 198, 198, 0.25)"}
      justifyContent={"space-between"}
      display={["flex", "flex", "flex", "none"]}
    >
      {
        !show ? null : (
          <IconButton
          px={0}
          minW={"auto"}
          onClick={onOpen}
          icon={<AiOutlineMenu />}
          bg="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
        />
        )
      }
      {
        logo && 
        <Box>
          <Image src={ncsLogo } alt="ncs logo" h={'50px'} w={'160px'}/>
        </Box>
      }
      
      {/* <Box px={5} w={'full'}>
        <InputGroup   >
          <InputLeftElement pointerEvents="none">
          <BiSearch color="gray" />
          </InputLeftElement>
          
          <Input placeholder="Search" bg={'#1C1C1C0D'}
            _placeholder={{ color: "#1C1C1C33" }} focusBorderColor="transparent" border={'none'}
          />
        </InputGroup>
      </Box> */}
      {
        logo? null : (
          <Box
          position={"relative"}
          // onClick={() => navigate("/notifications")}
          cursor={"pointer"}
        >
          <Circle
            position={"absolute"}
            top={".2rem"}
            right={".3rem"}
            bg={"red"}
            size={".3rem"}
          />
          <AiOutlineBell size={"1.5rem"} onClick={signOut} />
        </Box>
        )
      }
     

      <MobileSidebar btnRef={btnRef} isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default MobileHeader;
