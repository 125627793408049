import React from 'react';
import { format } from 'date-fns';
import {Flex, Text, Button, Box, Image} from '@chakra-ui/react';
import { useSelector } from 'react-redux';


const PrintTag = () => {
    const eventRegDetails = useSelector((state) => state?.eventRegistration?.eventRegDetails);
    // console.log("eventRegDetails", eventRegDetails);

    const tagData = [
      {title:"Event Name:", discription:eventRegDetails?.eventName},
      {title:"Unique ID:", discription:eventRegDetails?.uniqueId},
      {title:"Start Date:", discription:eventRegDetails?.eventStartDate && format(new Date(eventRegDetails?.eventStartDate), 'yyyy-MM-dd')},
      {title:"End Date:", discription:eventRegDetails?.eventEndDate && format(new Date(eventRegDetails?.eventEndDate), 'yyyy-MM-dd')},
    ]

  return (
    <Box py={5} >
      <Image src={eventRegDetails?.logoUrl} alt='Logo' width={'158px'} height={'49px'}/>
      <Flex justifyContent={'center'} alignItems={'center'} my={6}>
        <Image src={eventRegDetails?.profilePictureUrl} alt='Profile Pics' rounded={'full'} width={'289px'} height={'289px'} overflow={'hidden'} objectFit={'cover'} border={'8px solid #22944E'}
       
        />
      </Flex>
      <Text mt={10} mb={6} color={'#121212'} fontSize={'32px'} fontWeight={'700'} lineHeight={'33.6px'}>{eventRegDetails?.fullName}</Text>
      {
      tagData?.map((data, index) =>(
      <Flex my={5} fontSize={'16px'} fontWeight={'500'}  justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}>
          <Text width={'35%'} color={'#121212'}   >{data?.title}</Text>
          <Text  width={'65%'} color={'#7A7A7A'} >{data?.discription}</Text>
      </Flex>
      ))
      }
       <Button
          mt={10}
          width={"100%"}
          background={"#22944E"}
          _hover={{ background: " " }}
          color={"#FFFFFF"}
          type="submit"
          onClick={() => window.print()}
        >
          Print Tag
        </Button>
    </Box>
  )
}

export default PrintTag;