import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import fileUploadhttpRequest from "../../utils/fileUploadhttpRequest";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";


const initialState =  {
    speakers: [],
    loading: false,
    error: null,
    editSpeaker:"",
    speakerDetails: "",
};

export const fetchspeakers = createAsyncThunk("guestSpeaker/fetchspeakers", async () => {
    const response = httpRequest(`${BASE_URL}/GuestSpeaker/get-all-speakers`);
    // console.log("fetchspeakers", response);
    return response ;
});
export const createSpeaker = createAsyncThunk("guestSpeaker/createSpeaker", async (arg, { rejectWithValue }) => {
    try {
     const response = await fileUploadhttpRequest(`${BASE_URL}/GuestSpeaker/create-speaker`, "post", arg );
    //   console.log("createSpeaker", response);
     if (response?.response?.data) {
         throw response;
     }
      return response;
     
    } catch (error) {
     console.log("error: ",error?.response?.data);
     return rejectWithValue(error?.response?.data);
    }
 });

const guestSpeakerSlice = createSlice({
    name: "guestSpeaker",
    initialState,

    reducers: {
        setEditSpeaker: (state, { payload }) => {
            state.editSpeaker = payload;
        },

        setSpeakerDetails: (state, { payload }) => {
            state.speakerDetails = payload;
        },
    },

    extraReducers: (builder) => {
        // get all speakers.
        builder.addCase(fetchspeakers.pending, (state) =>{
            state.loading = true;
        });
        builder.addCase(fetchspeakers.fulfilled, (state, {payload}) =>{
            state.loading = false;
            state.speakers = payload?.data;
            state.error=null;
        });
        builder.addCase(fetchspeakers.rejected, (state, {payload}) =>{
            state.loading = false;
            state.speakers = [];
            state.error = payload?.error?.responseDescription;
        });

        // create speaker.
        builder.addCase(createSpeaker.pending, (state) =>{
            state.loading = true;
        });
        builder.addCase(createSpeaker.fulfilled, (state, {payload}) =>{
            state.loading = false;
            state.speakers.push(payload?.data);
            state.error=null;
        });
        builder.addCase(createSpeaker.rejected, (state, {payload}) =>{
            state.loading = false;
            state.error = payload?.responseDescription;
        });
    }

}); 



export const {setEditSpeaker, setSpeakerDetails}= guestSpeakerSlice.actions;
export default guestSpeakerSlice.reducer;