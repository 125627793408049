// import { AiOutlineDashboard, AiOutlineUser } from "react-icons/ai";
import { AiOutlineDashboard } from "react-icons/ai";
import { MdOutlineFolder, MdOutlineFeedback, 
  // MdOutlineVerifiedUser
 } from "react-icons/md";
import { TbBuildingEstate } from "react-icons/tb";
import { IoPeopleOutline, IoCalendarClearSharp } from "react-icons/io5";
import { HiOutlineDocumentArrowUp } from "react-icons/hi2";
import { RiShieldUserLine, RiSpeakerLine } from "react-icons/ri";

const menu = {
  "Admin Role": [
    { name: "Dashboard", link: "/dashboard", icon: AiOutlineDashboard },
    { name: "Membership Type", link: "/membership-type", icon: RiShieldUserLine },
    { name: "Event Management", link: "/event-management", icon: IoCalendarClearSharp },
    { name: "Event Speaker", link: "/event-speaker", icon: RiSpeakerLine },
    { name: "Upload Management", link: "/upload-management", icon: HiOutlineDocumentArrowUp  },
    { name: "Membership Management", link: "/membership-management", icon: IoPeopleOutline},
    { name: "Hotel / Accommodation", link: "/hotel-accommodation", icon: TbBuildingEstate },
    { name: "Report", link: "/report", icon: MdOutlineFolder },
    // { name: "Payment Verification", link: "/payment-verification", icon: MdOutlineVerifiedUser },
    { name: "Feedback", link: "/feedback", icon: MdOutlineFeedback },
    // { name: "Profile", link: "/profile", icon: AiOutlineUser },
  ],
};



export default menu;
