import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { setFormModal, setModalToggle } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { eventRegFormSchema } from "../utils/formValidationSchema";
import { fetchAllHotels } from "../redux/slices/hotelAccomSlice";
import { BsArrowLeft } from "react-icons/bs";
import {
  removeMemberCheckDetails,
  seteventPaymentDetails,
} from "../redux/slices/eventRegistrationSlice";
import fileUploadhttpRequest from "../utils/fileUploadhttpRequest";
import { BASE_URL } from "../config/settings";

const EventRegForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch(fetchAllHotels());
  }, [dispatch]);

  const eventData = useSelector(
    (state) => state?.eventRegistration?.eventDetails
  );
  // console.log("eventData modal reg form", eventData, eventData[0]?.id);

  const toggleModal = useSelector((state) => state.modal.modalToggle);
  const memberCheckDetails = useSelector(
    (state) => state?.eventRegistration?.memberCheckDetails
  );
  // console.log("memberCheckDetails", memberCheckDetails);

  const initialValues = {
    firstName: memberCheckDetails ? memberCheckDetails?.firstName : "",
    lastName: memberCheckDetails ? memberCheckDetails?.lastName : "",
    email: memberCheckDetails ? memberCheckDetails?.email : "",
    phoneNumber: memberCheckDetails ? memberCheckDetails?.phoneNumber : "",
    attendanceType: "",
    photo: "",
    // amount:'',
  };
  // console.log(memberCheckDetails);

  const handleSubmit = async (values) => {
    setError("");
    setLoading(true);
    try {
      const formData = {
        FirstName: values?.firstName,
        LastName: values?.lastName,
        Email: values?.email,
        PhoneNumber: values?.phoneNumber,
        attendanceType: values?.attendanceType,
        UploadPassport: values?.photo,
        EventManagementId: eventData[0]?.id,
        isMember: memberCheckDetails?.isMember,
        membershipType: memberCheckDetails?.membershipType,
        memberId: memberCheckDetails?.memberId,

        // amount:values.amount,
      };
      // console.log("formData", formData);

      const response = await fileUploadhttpRequest(
        `${BASE_URL}/Registration/register`,
        "post",
        formData
      );

      if (response.isSuccessful === true) {
        setLoading(false);
        if (toggleModal === true) {
          dispatch(setModalToggle());
        }
        dispatch(removeMemberCheckDetails());
        dispatch(seteventPaymentDetails(response?.data));
        dispatch(
          setFormModal({
            status: true,
            title: "Event Payment Details",
            type: "eventPayment",
          })
        );
      } else {
        setError(
          response?.response?.data?.error?.responseDescription ||
            "Something went wrong. Please try again"
        );
      }

      // console.log("response", response);
    } catch (error) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}

      {toggleModal && (
        <BsArrowLeft
          style={{
            marginTop: "10px",
            width: "20px",
            height: "20px",
            color: "#292D32",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(removeMemberCheckDetails());
            dispatch(setModalToggle());
            dispatch(setFormModal({ status: false }));
          }}
        />
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={eventRegFormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <FormControl
              mt={4}
              isInvalid={errors.firstName && touched.firstName}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel htmlFor="firstName" fontSize={"16px"}>
                First Name
              </FormLabel>
              <Input
                placeholder="Enter First Name"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="firstName"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.firstName}
                readOnly
              />
              {touched.firstName && errors.firstName && (
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.lastName && touched.lastName}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel htmlFor="lastName" fontSize={"16px"}>
                Last Name
              </FormLabel>
              <Input
                placeholder="Enter Last Name"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="lastName"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.lastName}
                readOnly
              />
              {touched.lastName && errors.lastName && (
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.email && touched.email}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel htmlFor="email" fontSize={"16px"}>
                Email Address
              </FormLabel>
              <Input
                placeholder="Enter Email Address"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="email"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.email}
              />
              {touched.email && errors.email && (
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.phoneNumber && touched.phoneNumber}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel htmlFor="phoneNumber" fontSize={"16px"}>
                Phone Number
              </FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="phoneNumber"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="tel"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.phoneNumber}
              />
              {touched.phoneNumber && errors.phoneNumber && (
                <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              color="#121212"
              fontFamily={"inter"}
              isInvalid={errors.attendanceType && touched.attendanceType}
            >
              <FormLabel htmlFor="attendanceType" fontSize={"16px"}>
                Attendance Type
              </FormLabel>
              <Select
                name="attendanceType"
                placeholder="Select attendanceType"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.attendanceType}
              >
                <option value="physical">Physical</option>
                <option value="virtual">Virtual</option>
                <option key="Author" value="Author">
                  Author
                </option>
              </Select>
              {touched.attendanceType && errors.attendanceType && (
                <FormErrorMessage>{errors.attendanceType}</FormErrorMessage>
              )}
            </FormControl>
            {/* <FormControl mt={4} isInvalid={errors.amount && touched.amount}
        color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='amount' fontSize={"16px"}> Amount</FormLabel>
        <Input
            type="number"
            placeholder="Enter Amount"
            borderRadius={"0"}
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="amount"
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            _placeholder={{ color: "#B4B1B1" }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.amount}
        />
        {touched.amount && errors.amount && (
            <FormErrorMessage>{errors.amount}</FormErrorMessage>
        )}
        </FormControl> */}
            <FormControl
              mt={4}
              isInvalid={errors.photo && touched.photo}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel htmlFor="photo" fontSize={"16px"}>
                Upload Photo
              </FormLabel>
              <Input
                type="file"
                name="photo"
                id="photo"
                fontSize={"small"}
                bg={"#F3F3F3"}
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue("photo", event.currentTarget.files[0]);
                }}
                accept="image/*"
              />
              {touched.photo && errors.photo && (
                <FormErrorMessage>{errors.photo}</FormErrorMessage>
              )}
            </FormControl>
            <Button
              mt={10}
              width={"100%"}
              background={"#22944E"}
              _hover={{ background: " " }}
              color={"#FFFFFF"}
              type="submit"
              isLoading={loading}
              loadingText="Registering ..."
            >
              Register
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EventRegForm;
