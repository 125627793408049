import React from 'react';
import { useSelector } from 'react-redux';
import EventRegModal1 from './EventRegModal1';
import EventRegModal2 from './EventRegModal2';

const EventRegModalContainer = () => {

    const toggleModal = useSelector((state) => state.modal.modalToggle);

  return (
    <>
    {
        !toggleModal ? 
        ( 
          <EventRegModal1 />
        ) : 
        (
          <EventRegModal2 />
        )
    }
    </>
  )
}

export default EventRegModalContainer