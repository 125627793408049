import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import CustomTable from "./CustomTable";

const DetailRow = ({ label, value }) => {
  return (
    <Flex gap={4}>
      <Text flexBasis={"25%"} fontWeight={"bold"} color={"#BCBCBC"}>
        {label} :
      </Text>
      <Text fontWeight={"bold"} color={"#888888"}>
        {value}
      </Text>
    </Flex>
  );
};
const EventDetails = () => {
  const currentEvent = useSelector((state) => state?.events?.currentEvent);
  const eventAttendance = useSelector((state) => state?.events?.eventAttendance);

  const tableProps = {
    data: eventAttendance,
    title: {
      name: "Name",
      email: "Email",
      attendanceType:"Attendance Type",
      membershipType: "Membership Type"
    },
    dataKeys: [
      "name",
      "email",
      "attendanceType",
      "membershipType"
    ],
    variant: "true",
    transformRow: (item) => ({
      name: <Box>{item?.firstName} {item?.lastName}</Box>,
      email: <Box>{item?.email}</Box>,
      attendanceType:<Box>{item?.attendanceType}</Box>,
      membershipType: <Box>{item?.membershipType}</Box>
    }),
  };

  return (
    <Box>
      <Flex bg={"#D6FFDB"} h={"4rem"} mx={"-6"} p={4} px={8} alignItems={"center"}>
        <Text fontSize={"1rem"} fontWeight={"bold"} color={"#22944E"}>
          {currentEvent?.name}
        </Text>
      </Flex>
      <Flex direction={"column"} gap={4} py={4} px={3}>
        <DetailRow
          label={"Start Date"}
          value={new Date(currentEvent.startDate).toISOString().split("T")[0]}
        />
        <DetailRow
          label={"End Date"}
          value={new Date(currentEvent.endDate).toISOString().split("T")[0]}
        />
        <DetailRow label={"Location"} value={currentEvent.location} />
        <DetailRow label={"Event Type"} value={currentEvent.eventType} />
        <DetailRow label={"Description"} value={currentEvent.information} />
      </Flex>
      <Text color={"#22944E"} fontWeight={"bold"} my={5}>Event Attendance</Text>
      <Box h={'300px'} overflow={'scroll'}>
        <CustomTable {...tableProps} />
      </Box>
    </Box>
  );
};

export default EventDetails;
