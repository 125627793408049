import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { PiPlusBold } from "react-icons/pi";

const Create = ({ onClick, createText }) => {
  return (
    <Flex
      alignItems={"center"}
      gap={5}
      flexDirection={["column", "row", "row"]}
    >
      <Button
        borderRadius={"3px"}
        bg={"brand.primary"}
        leftIcon={<PiPlusBold />}
        color="white"
        onClick={onClick}
        px={16}
        _hover={{ backgroundColor: "" }}
      >
        {createText}
      </Button>
    </Flex>
  );
};

export default Create;
