import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import fileUploadhttpRequest from "../../utils/fileUploadhttpRequest";
import { BASE_URL } from "../../config/settings";

const initialState = {
  members: [],
  loading: false,
  error: null,
  editMember: "",
  memberDetails: "",
};

export const fetchUploadedMembers = createAsyncThunk(
  "membershipMgt/fetchUploadedMembers",
  async () => {
    const response = httpRequest(
      `${BASE_URL}/MembershipManagement/get-all-uploadedMember`
    );
    return response;
  }
);

export const uploadedMembers = createAsyncThunk(
  "membershipMgt/uploadedMembers",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/MembershipManagement/upload-member`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      //  console.log("error: ",error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const bulkUploadMembers = createAsyncThunk(
  "membershipMgt/bulkUploadMembers",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await fileUploadhttpRequest(
        `${BASE_URL}/MembershipManagement/bulk-upload`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      //  console.log("error: ",error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const bulkUploadGuests = createAsyncThunk(
  "membershipMgt/bulkUploadGuests",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await fileUploadhttpRequest(
        `${BASE_URL}/Registration/bulk-register`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      //  console.log("error: ",error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const membershipMgtSlice = createSlice({
  name: "membershipMgt",
  initialState,

  reducers: {
    setMemberDetails: (state, { payload }) => {
      state.memberDetails = payload;
    },
    setEditMember: (state, { payload }) => {
      state.editMember = payload;
    },
  },

  extraReducers: (builder) => {
    // fetch Uploaded Members :
    builder.addCase(fetchUploadedMembers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(fetchUploadedMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.members = payload?.data;
      state.error = null;
    });

    builder.addCase(fetchUploadedMembers.rejected, (state, { payload }) => {
      state.loading = false;
      state.members = [];
      state.error = payload?.error?.responseDescription;
    });

    // Upload Members :
    builder.addCase(uploadedMembers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(uploadedMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.members.push(payload?.data);
      state.error = null;
    });

    builder.addCase(uploadedMembers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.responseDescription;
    });

    // Bulk Upload Members :
    builder.addCase(bulkUploadMembers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(bulkUploadMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.members.push(payload?.data);
      state.error = null;
    });

    builder.addCase(bulkUploadMembers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.responseDescription;
    });
  },
});

export const { setMemberDetails, setEditMember } = membershipMgtSlice.actions;
export default membershipMgtSlice.reducer;
