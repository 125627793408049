import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import MembershipTypeTable from "../components/MembershipTypeTable";
import CreateAndFilter from "../components/layout/CreateAndFilter";
import { setFormModal } from "../redux/slices/modalSlice";

const MembershipType = () => {
  const dispatch = useDispatch();
  const createData = {
    createText: "Add New Membership Type",
    onClick: () => {
      dispatch(
        setFormModal({
          status: true,
          title: "Add New Membership Type",
          type: "addMembershipType",
        })
      );
    },
  };

  return (
    <Box my={4} bg={"#FFFFFF"} minH={"100vh"}>
      <Flex
        flexDirection={["column", "row", "row"]}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"20px"}
        borderBottom={"1px solid #B4B1B1"}
        py={"20px"}
        px={"20px"}
        mb={5}
      >
        <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
          Membership Type
        </Text>
        <CreateAndFilter {...createData} />
      </Flex>
      <MembershipTypeTable />
    </Box>
  );
};

export default MembershipType;
