import React, {useState, useEffect} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Alert,AlertIcon, AlertTitle, Textarea } from "@chakra-ui/react";
import {setFormModal} from "../redux/slices/modalSlice";
import { useDispatch, useSelector} from 'react-redux';
import {createSpeaker} from "../redux/slices/guestSpeakerSlice";
import {fetchAllEvents} from "../redux/slices/eventRegistrationSlice";
import {addEventSpeakerSchema} from "../utils/formValidationSchema";
import {fetchspeakers} from "../redux/slices/guestSpeakerSlice";

const AddEventSpeaker = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch(fetchAllEvents());
  }, [dispatch]);
  const allEvents = useSelector((state) => state.eventRegistration?.allEvents);

  const initialValues = {
    firstName: '',
    lastName:'',
    biography:'',
    topic: '',
    event:'',
    photo:''
  };

  const handleSubmit = async (values) => {
    setError("");
    setLoading(true);
   try {
    const formData = {
      FirstName: values.firstName,
      LastName:values.lastName,
      Biography: values.biography,
      Topic: values.topic,
      EventId: parseInt(values.event, 10),
      Image: values.photo,
      // "isActive": true,
    };

    console.log("formData", formData);
    const response = await dispatch(createSpeaker(formData)) ;
    // console.log("response", response);
    if(!response?.payload.isSuccessful === true){
      setLoading(false);
      setError(response?.payload.responseDescription || "Something went wrong. Please try again");
      return;
    }
      setLoading(false);
      await dispatch(fetchAllEvents());
      await dispatch(fetchspeakers());
      dispatch(setFormModal({ status: false }));
   
   } catch (error) {
    setError(error?.message || "Something went wrong. Please try again");
   }finally{
    setLoading(false);
   }
  };
  
  return (
    <>
    {
     error &&(
     <Alert status="error" className="mt-3">
         <AlertIcon />
         <AlertTitle>{ error}</AlertTitle>
     </Alert>
     )
   }
   <Formik
   enableReinitialize
   initialValues={initialValues}
   validationSchema={addEventSpeakerSchema}
   onSubmit={(values) => handleSubmit(values)}
   >
   {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
     <Form>
       <FormControl mt={4} isInvalid={errors.firstName && touched.firstName}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='firstName' fontSize={"16px"}>First Name</FormLabel>
          <Input
            placeholder="Enter First Name"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="firstName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.firstName}
          />
          {touched.firstName && errors.firstName && (
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.lastName && touched.lastName}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='lastName' fontSize={"16px"}>Last Name</FormLabel>
          <Input
            placeholder="Enter Last Name"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="lastName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.lastName}
          />
          {touched.lastName && errors.lastName && (
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          )}
        </FormControl>
       <FormControl mt={4} isInvalid={errors.biography && touched.biography}
       color="#121212" fontFamily={'inter'}>
       <FormLabel htmlFor='biography' fontSize={"16px"}> Biography</FormLabel>
       <Textarea
          placeholder="Enter Biography"
          borderRadius={"0"}
          fontSize={"small"}
          bg={'#F3F3F3'}
          name="biography"
          focusBorderColor="#ffffff"
          borderColor={"#FFFFFF"}
          _hover={{ borderColor: "#FFFFFF" }}
          _placeholder={{ color: "#B4B1B1" }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.biography}
      />
       {touched.biography && errors.biography && (
           <FormErrorMessage>{errors.biography}</FormErrorMessage>
       )}
       </FormControl>
       <FormControl mt={4} isInvalid={errors.topic && touched.topic}
           color="#121212" fontFamily={'inter'}>
         <FormLabel htmlFor='topic' fontSize={"16px"}>Topic</FormLabel>
         <Input
           placeholder="Enter Here"
           fontSize={"small"}
           bg={'#F3F3F3'}
           name="topic"
           focusBorderColor="#ffffff"
           borderRadius={'0'}
           borderColor={"#FFFFFF"}
           _placeholder={{ color: "#B4B1B1" }}
           _hover={{ borderColor: "#FFFFFF" }}
           type="text"
           onBlur={handleBlur}
           onChange={handleChange}
           value={values?.topic}
         />
         {touched.topic && errors.topic && (
           <FormErrorMessage>{errors.topic}</FormErrorMessage>
         )}
       </FormControl>
       <FormControl mt={4} isInvalid={errors.event && touched.event}
           color="#121212" fontFamily={'inter'}>
         <FormLabel htmlFor='event'  fontSize={"16px"}>Event Name</FormLabel>
         <Select
             name="event"
             onBlur={handleBlur}
             onChange={handleChange}
             bg={'#F3F3F3'}
             borderRadius={"0"}
             focusBorderColor="#ffffff"
             borderColor={"#FFFFFF"}
             placeholder='Select Event'
             _hover={{ borderColor: "#FFFFFF" }}
             _placeholder={{ color: "#B4B1B1" }}
             value={values?.event}
           >
              {
                allEvents?.map((event) => (
                  <option key={event?.id} value={event?.id}>{event?.name}</option>
                ))
              }
           </Select>
         {touched.event && errors.event && (
           <FormErrorMessage>{errors.event}</FormErrorMessage>
         )}
       </FormControl>
       <FormControl mt={4} isInvalid={errors.photo && touched.photo}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='photo' fontSize={"16px"}>
            Upload Photo
          </FormLabel>
          <Input 
            type='file'
            name="photo"
            id="photo"
            fontSize={"small"}
            bg={'#F3F3F3'}
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            onBlur={handleBlur}
            onChange={(event) => {
              setFieldValue('photo', event.currentTarget.files[0]);
            }}
            accept="image/*"
          />
          {touched.photo && errors.photo && (
            <FormErrorMessage>{errors.photo}</FormErrorMessage>
          )}
        </FormControl>
       <Button
         mt={10}
         width={"100%"}
         background={"#22944E"}
         _hover={{ background: " " }}
         color={"#FFFFFF"}
         type="submit"
         isLoading={loading}
         loadingText="Saving ..."
       >
         Add Speaker
       </Button>
     </Form>
   )}
 </Formik>
   </>
  )
}

export default AddEventSpeaker;