import React, {useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import {setFormModal} from "../redux/slices/modalSlice";
import { useDispatch } from 'react-redux';
import {addmembershipFormSchema} from "../utils/formValidationSchema";
import {uploadedMembers, fetchUploadedMembers} from "../redux/slices/membershipMgtSlice";

const AddMemberMgt = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
  
    const initialValues = {
        memberShipCode: '',
        firstName:'',
        lastName:'',
        // email:'',
        phoneNumber: '',
        membershipType:'',
        dateJoined:'',
    };


    const handleSubmit = async (values) => {
      setLoading(true);
      setError("");
     try {
      const formData = {
        memberShipCode:values.memberShipCode,
        firstName:values.firstName,
        lastName:values.lastName,
        // email:values.email,
        phoneNumber: values.phoneNumber,
        membershipType:values.membershipType,
        // dateJoined:values.dateJoined,
        // "isActive": true,
      };
      const response = await dispatch(uploadedMembers(formData));
       
      if(!response?.payload.isSuccessful === true){
        setLoading(false);
        setError(response?.payload.message || "Something went wrong. Please try again");
        dispatch(fetchUploadedMembers());
        return;
      }
      setLoading(false);
      dispatch(setFormModal({ status: false }));
      
    } catch (error) {
      setError(error?.message || "Something went wrong. Please try again");
     }finally{
      setLoading(false);
     }
    };

  return (
  <>
    {
      error &&(
      <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{ error}</AlertTitle>
      </Alert>
      )
    }
     <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={addmembershipFormSchema}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange }) => (
      <Form>
        <FormControl mt={4} isInvalid={errors.memberShipCode && touched.memberShipCode}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='memberShipCode' fontSize={"16px"}>Membership code</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="memberShipCode"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.memberShipCode}
          />
          {touched.memberShipCode && errors.memberShipCode && (
            <FormErrorMessage>{errors.memberShipCode}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.firstName && touched.firstName}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='firstName' fontSize={"16px"}>First Name</FormLabel>
          <Input
            placeholder="Enter First Name"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="firstName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.firstName}
          />
          {touched.firstName && errors.firstName && (
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.lastName && touched.lastName}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='lastName' fontSize={"16px"}>Last Name</FormLabel>
          <Input
            placeholder="Enter Last Name"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="lastName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.lastName}
          />
          {touched.lastName && errors.lastName && (
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          )}
        </FormControl>
        {/* <FormControl mt={4} isInvalid={errors.email && touched.email}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='email' fontSize={"16px"}>Email Address</FormLabel>
          <Input
            placeholder="Enter Email Address"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="email"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.email}
          />
          {touched.email && errors.email && (
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          )}
        </FormControl> */}
        <FormControl mt={4} isInvalid={errors.phoneNumber && touched.phoneNumber}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='phoneNumber' fontSize={"16px"}>Phone Number</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="phoneNumber"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="tel"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.phoneNumber}
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.membershipType && touched.membershipType}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='membershipType'  fontSize={"16px"}>Membership Type</FormLabel>
          <Select
              name="membershipType"
              placeholder="Select membership Type"
              onBlur={handleBlur}
              onChange={handleChange}
              bg={'#F3F3F3'}
              borderRadius={"0"}
              focusBorderColor="#ffffff"
              borderColor={"#FFFFFF"}
              _hover={{ borderColor: "#FFFFFF" }}
              _placeholder={{ color: "#B4B1B1" }}
            >
              
              <option  value={'MEMBER'}>Member</option>
              <option  value={'Non Member'}>Non Member</option>
              <option  value={'FELLOW'}>Fellow</option>
              <option  value={'Fellow-R'}>Fellow-R</option>
              <option  value={'ASSOCIATE'}>Associate</option>
             
            </Select>
          {touched.membershipType && errors.membershipType && (
            <FormErrorMessage>{errors.membershipType}</FormErrorMessage>
          )}
        </FormControl>
        {/* <FormControl mt={4} isInvalid={errors.dateJoined && touched.dateJoined}
        color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='dateJoined' fontSize={"16px"}> Date Joined</FormLabel>
        <Input
            type="date"
            placeholder="Pick a Date"
            borderRadius={"0"}
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="dateJoined"
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            _placeholder={{ color: "#B4B1B1" }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.dateJoined}
        />
        {touched.dateJoined && errors.dateJoined && (
            <FormErrorMessage>{errors.dateJoined}</FormErrorMessage>
        )}
        </FormControl> */}
        <Button
          mt={10}
          width={"100%"}
          background={"#22944E"}
          _hover={{ background: " " }}
          color={"#FFFFFF"}
          type="submit"
          isLoading={loading}
          loadingText="Saving ..."
        >
          Save
        </Button>
      </Form>
    )}
    </Formik>
  </>
   
  )
}

export default AddMemberMgt;