import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import loginBg from  "../Assets/Images/login-imgBg.jpg";

const LoginImage = () => {
  return (
    <Box
      flexBasis={[0, 0, "40%", "50%"]}
      display={["none", "none", "block", "block"]}
      h="full"
      flexShrink={"0"}
      // p={5}
      bgImage={loginBg}
      bgPosition={'center'}
      bgRepeat={'no-repeat'}
    >
      <Flex
       bg={"rgba(35, 164, 85, 0.80)"}
        p={8}
        direction={"column"}
        h="full"
        justify={"center"}
        alignItems={"center"}
      >
        <Text
          fontSize={"40px"}
          fontWeight={"700"}
          color={"white"}
          lineHeight={"48px"}
          px={10}
          textAlign={"right"}
        >
          Welcome aboard our event platform where experiences comes to life 
          <br/><span style={{fontSize:'20px', fontWeight: '500'}}>Let’s create, connect and celebrate together.</span>
        </Text>

      </Flex>
    </Box>
  );
};

export default LoginImage;
