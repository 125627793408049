import React, { useCallback, useEffect, useState} from 'react';
import { useDispatch} from 'react-redux';
import httpRequest from '../utils/httpRequest';
import { BASE_URL } from '../config/settings';
import { Text, Alert, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
// import { setFormModal} from "../redux/slices/modalSlice";
import {seteventRegDetails} from "../redux/slices/eventRegistrationSlice";

const PaymentReturn = () => {
const dispatch = useDispatch();
const [loading, setLoading] = useState(false);
const [error, setError] = useState("");
const errorsArray = Array.isArray(error) ? error : [error];
const paymentDetails = JSON.parse(localStorage.getItem('paymentDetails'));
const reference = paymentDetails?.reference;
 const registrationFormId = paymentDetails?.registrationFormId;
// const registrationFormId = 32;
// console.log('hello, welcome', reference, registrationFormId);
const verifyPayment = useCallback(async() =>{
    setError("");
    setLoading(true);
    try {
        const response = await httpRequest(
            `${BASE_URL}/Payment/Verify?reference=${reference}&registrationFormId=${registrationFormId}`, 
            // `${BASE_URL}/Payment/Verify?reference=157121453&registrationFormId=10048`, 
        );
        // console.log("response payment return", response);
        if(response.isSuccessful === true){
            setLoading(false);
            dispatch(seteventRegDetails(response.data));
            // dispatch(setFormModal({status:true, type:"prinTag"}));
        }else{
            setLoading(false);
            // setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.responseDescription ||  "Failed to verify payment. Please try again");
        }
    } catch (error) {
        // setError(error?.message || "Something went wrong. Please try again");
    }finally{
        setLoading(false);
    }
}, [reference, registrationFormId, dispatch])

useEffect( () => {
    if( reference && registrationFormId ){
        verifyPayment() 
    }
}, [reference, registrationFormId, verifyPayment]);


  return (
    <>
    {
        error &&(
        <Alert status="error" className="mt-3">
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
    }
    
        <Flex gap={3} flexDirection={'column'} justifyContent={'center'}alignItems={'center'} mt={10}>
            {
                loading && <Text >Verifying payment</Text>
            }
            <Link to={'/all-events'} style={{color:'#22944E', textDecoration:'underline'}}>Go back to events</Link>
        </Flex>
    </>
  )
}

export default PaymentReturn;