export const styles = {
  dashboard: {
    cards: {
      width: {
        base: "100%",
        md: "calc(50% - 8px)",
        lg: "calc(50% - 8px)",
        xl: "calc(25% - 8px)",
      },
      minHeight: "116px",
      marginBottom: { base: 4, md: 2, lg: 2 },
      backgroundColor: "#fff",
      borderRadius: "8px",
      border: "1px solid #6EC1E4",
      justifyContent: "center",
    },
  },
};
