import React from 'react';
import '../../Assets/css/dotLoader.css';

const DotLoader = () => {
    
  return (
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  )
}

export default DotLoader;