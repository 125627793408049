import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";


export const handleDeativateHotel = async (item) =>{
    try {
      if (item.isActive) {
          await httpRequest(
              `${BASE_URL}/Hotel/deactivate-hotel?hotelId=${item.id}`,
              "post",
          );
      } else {
          await httpRequest(
              `${BASE_URL}/Hotel/activate-hotel?hotelId=${item.id}`,
              "post",
          );
      }
      
    } catch (error) {
        console.log(error);
    }
}

export const handleDeativateMembership = async (item) =>{
    try {
        if (item.isActive) {
            await httpRequest(
                `${BASE_URL}/MembershipManagement/deactivate-upload-member?memberId=${item.id}`,
                "post",
            );
        } else {
            await httpRequest(
                `${BASE_URL}/MembershipManagement/activate-upload-member?memberId=${item.id}`,
                "post",
            );
        }
        
      } catch (error) {
          console.log(error);
    }
}