import React from "react";
import ReportPanel from "./layout/ReportPanel";
import { Box, Button, Flex } from "@chakra-ui/react";
import CustomTable from "./CustomTable";
import DownloadBTN from "../components/DownloadBTN";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { setFormModal } from "../redux/slices/modalSlice";

const ReportNewProject = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports?.reports);

  const handleUpdate = async (item) => {
    // console.log("item",item);
    dispatch(
      setFormModal({
        status: true,
        title: "Event Report Details",
        type: "eventReportDetails",
        size: "xl",
        data: item,
      })
    );
  };

  const tableProps = {
    data: reports,
    title: {
      eventName: "Event Name",
      startDate: "Start Date",
      endDate: "End Date",
      // membershipType: "Membership type",
      eventType: "eventType",
      amount: "Total Amount",
      status: "status",
    },
    dataKeys: [
      "eventName",
      "startDate",
      "endDate",
      // "membershipType",
      "eventType",
      "amount",
      "status",
    ],
    variant: true,
    sn: true,
    transformRow: (item) => ({
      eventName: (
        <Box cursor={"pointer"} onClick={() => handleUpdate(item)}>
          {item.eventName}
        </Box>
      ),
      startDate: item.startDate && (
        <Box cursor={"pointer"} onClick={() => handleUpdate(item)}>
          {format(new Date(item.startDate), "yyyy-MM-dd")}
        </Box>
      ),
      endDate: item.endDate && (
        <Box cursor={"pointer"} onClick={() => handleUpdate(item)}>
          {format(new Date(item.endDate), "yyyy-MM-dd")}
        </Box>
      ),
      // memebershipType: item.membershipTypes.map((type) => type),
      eventType: (
        <Box cursor={"pointer"} onClick={() => handleUpdate(item)}>
          {item.eventType}
        </Box>
      ),
      amount: (
        <Box cursor={"pointer"} onClick={() => handleUpdate(item)}>
          {item.totalAmount}
        </Box>
      ),
      status: (
        <Button
          // bg={item.isActive ? "#E1E9FF" : "#FCF6DF"}
          // color={item.isActive ? "#356EFF" : "#E9B500"}
          color={"#356EFF"}
          _hover={{ backgroundColor: "" }}
        >
          {/* {item.isActive ? "Active" : "Inactive"} */}
          Active
        </Button>
      ),
    }),
  };

  return (
    <>
      <ReportPanel />

      <Box>
        <Flex
          flexDirection={["column", "column", "row"]}
          gap={4}
          justifyContent={["center", "center", "flex-end"]}
          mt={6}
          mb={4}
        >
          {/* <FormControl w={['100%', '65%', '30%', "25%"]}>
                    <Select bg={'#FFFFFF'}  focusBorderColor="0.5px solid brand.primary" _hover={{border: ""}} border={'1px solid #22944E'}
                            _placeholder={{ color: "#8A8C8E" }} color={'brand.primary'} borderRadius={'0'} size={'lg'}
                        >
                            <option value='Filter by Ref Code' selected 
                            >Filter by Code</option>
                            <option value='Filter by Name' >Filter by Name</option>
                            <option value='Filter by Status'>Filter by Status</option>
                        </Select>
                   </FormControl> */}

          <DownloadBTN btn_Text="Download" bg={"#61CE70"} />
        </Flex>

        <CustomTable {...tableProps} />
      </Box>
    </>
  );
};

export default ReportNewProject;
