import React,  {useState }  from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import {setFormModal} from "../redux/slices/modalSlice";
import { useSelector, useDispatch } from 'react-redux';
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import {fetchAllHotels} from "../redux/slices/hotelAccomSlice";

const EditHotel = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const editHotelData = useSelector((state) => state?.hotel?.editHotel);
  const id = editHotelData.id;
  
  const initialValues = {
      hotelName: editHotelData.hotelName,
      location:editHotelData.hotelAddress,
      contact:editHotelData.contact,
      hotelType:editHotelData.hotelType,
      roomType:editHotelData.roomType,
      amount:editHotelData.amount,

  };

  const handleSubmit = async (values) => {
    setError("");
    setLoading(true);

    const formData = {
      hotelName: values.hotelName,
      hotelAddress: values.location,
      contact: values.contact,
      hotelType: values.hotelType,
      roomType: values.roomType,
      amount: values.amount,
      "isActive":editHotelData.isActive,
    };
    try {
      const response = await httpRequest(
        `${BASE_URL}/Hotel/edit-hotel?hotelId=${id}`, 
        "post", 
        formData,
      );

      if(response.isSuccessful === true){
        setLoading(false);
        await dispatch(fetchAllHotels());
        dispatch(setFormModal({ status: false }));
      }else{
        setError(response?.message || response?.response?.data?.responseDescription || "Something went wrong. Please try again");
    }
    } catch (error) {
      setError(error?.message);
    }finally{
      setLoading(false);
    }
  };

  return (
  <>
    {
      error &&(
      <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{ error}</AlertTitle>
      </Alert>
      )
    }
  <Formik
  enableReinitialize
  initialValues={initialValues}
  onSubmit={(values) => handleSubmit(values)}
  >
  {({ errors, touched, values, handleBlur, handleChange }) => (
    <Form>
      <FormControl mt={4} isInvalid={errors.hotelName && touched.hotelName}
          color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='hotelName' fontSize={"16px"}>Hotel Name</FormLabel>
        <Input
          fontSize={"small"}
          bg={'#F3F3F3'}
          name="hotelName"
          focusBorderColor="#ffffff"
          borderRadius={'0'}
          borderColor={"#FFFFFF"}
          _placeholder={{ color: "#B4B1B1" }}
          _hover={{ borderColor: "#FFFFFF" }}
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.hotelName}
        />
        {touched.hotelName && errors.hotelName && (
          <FormErrorMessage>{errors.hotelName}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl mt={4} isInvalid={errors.location && touched.location}
          color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='location' fontSize={"16px"}>Location</FormLabel>
        <Input
          fontSize={"small"}
          bg={'#F3F3F3'}
          name="location"
          focusBorderColor="#ffffff"
          borderRadius={'0'}
          borderColor={"#FFFFFF"}
          _placeholder={{ color: "#B4B1B1" }}
          _hover={{ borderColor: "#FFFFFF" }}
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.location}
        />
        {touched.location && errors.location && (
          <FormErrorMessage>{errors.location}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl mt={4} isInvalid={errors.contact && touched.contact}
          color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='contact' fontSize={"16px"}>Contact</FormLabel>
        <Input
          fontSize={"small"}
          bg={'#F3F3F3'}
          name="contact"
          focusBorderColor="#ffffff"
          borderRadius={'0'}
          borderColor={"#FFFFFF"}
          _placeholder={{ color: "#B4B1B1" }}
          _hover={{ borderColor: "#FFFFFF" }}
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.contact}
        />
        {touched.contact && errors.contact && (
          <FormErrorMessage>{errors.contact}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl mt={4} isInvalid={errors.hotelType && touched.hotelType}
          color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='hotelType' fontSize={"16px"}>Hotel Type</FormLabel>
        <Input
          fontSize={"small"}
          bg={'#F3F3F3'}
          name="hotelType"
          focusBorderColor="#ffffff"
          borderRadius={'0'}
          borderColor={"#FFFFFF"}
          _placeholder={{ color: "#B4B1B1" }}
          _hover={{ borderColor: "#FFFFFF" }}
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.hotelType}
        />
        {touched.hotelType && errors.hotelType && (
          <FormErrorMessage>{errors.hotelType}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl mt={4} isInvalid={errors.roomType && touched.roomType}
          color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='roomType'  fontSize={"16px"}>Room Type</FormLabel>
        <Select
            name="roomType"
            onBlur={handleBlur}
            onChange={handleChange}
            bg={'#F3F3F3'}
            borderRadius={"0"}
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            _placeholder={{ color: "#B4B1B1" }}
            value={values?.roomType}
          >
            
          <option value={'Standard'}>Standard</option>
          <option  value={'Double'}>Double</option>
           
          </Select>
        {touched.roomType && errors.roomType && (
          <FormErrorMessage>{errors.roomType}</FormErrorMessage>
        )}
      </FormControl>
      
      <FormControl mt={4} isInvalid={errors.amount && touched.amount}
      color="#121212" fontFamily={'inter'}>
      <FormLabel htmlFor='amount' fontSize={"16px"}> Amount</FormLabel>
      <Input
          type="number"
          borderRadius={"0"}
          fontSize={"small"}
          bg={'#F3F3F3'}
          name="amount"
          focusBorderColor="#ffffff"
          borderColor={"#FFFFFF"}
          _hover={{ borderColor: "#FFFFFF" }}
          _placeholder={{ color: "#B4B1B1" }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.amount}
      />
      {touched.amount && errors.amount && (
          <FormErrorMessage>{errors.amount}</FormErrorMessage>
      )}
      </FormControl>
      <Button
        mt={10}
        width={"100%"}
        background={"#22944E"}
        _hover={{ background: " " }}
        color={"#FFFFFF"}
        type="submit"
        isLoading={loading}
        loadingText="Saving ..."
      >
        Save
      </Button>
    </Form>
  )}
</Formik>

  </>
  )
}

export default EditHotel;