import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from "formik";
import { Box, Text, Textarea, Button, FormControl, FormErrorMessage, FormLabel, Input, Alert,AlertIcon, AlertTitle, Flex, Image } from '@chakra-ui/react';
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {feedbackFormSchema} from "../utils/formValidationSchema";
import ncsLogo from "../Assets/Images/ncs-logo.png";

const FeedBackForm = () => {
  const {id} = useParams();
  

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

    
  const initialValues = {
    feedback: '',
    rating:'',
    suggestion:'',

};

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    setError("");

    const formData = {
      feedback: values.feedback,
      rating: parseInt(values.rating, 0),
      suggestion: values.suggestion,
      eventId: id,
    };
    // console.log("formData", formData);

    try {
      const response = await httpRequest(
        `${BASE_URL}/Feedback/add-feedback`,
        'post',
        formData
      );
      // console.log("response", response);
      if(response?.isSuccessful === true){
        setLoading(false); 
        resetForm(initialValues);       
        return;
      }else{
        setLoading(false);
        setError(response?.message || "Something went wrong. Please try again");
      }
    
    } catch (error) {
      setError(error?.message || "Something went wrong. Please try again");
    }finally{
      setLoading(false);
    }
  };
  
  return (
    <Box px={10} pb={10}>
      <Box py={3}>
        <Image src={ncsLogo} alt="ncs-logo" w={'200px'}  h={'50px'}/>
      </Box>
      <Text fontWeight={'700'} fontSize={'20px'} textAlign={'center'} py={10}> Feedback Form</Text>
      
      {
        error &&(
        <Alert status="error" className="mt-3">
            <AlertIcon />
            <AlertTitle>{ error}</AlertTitle>
        </Alert>
        )
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={feedbackFormSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Form>
            <FormControl mt={4} isInvalid={errors.feedback && touched.feedback}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='feedback' fontSize={"16px"}>Feedback</FormLabel>
              <Textarea
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="feedback"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.feedback}
              />
              {touched.feedback && errors.feedback && (
                <FormErrorMessage>{errors.feedback}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.rating && touched.rating}
            color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='rating' fontSize={"16px"}> Rating</FormLabel>
            <Input
                type="number"
                placeholder="Enter rating from 1(least) to 5(highest)"
                borderRadius={"0"}
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="rating"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.rating}
            />
            {touched.rating && errors.rating && (
                <FormErrorMessage>{errors.rating}</FormErrorMessage>
            )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.suggestion && touched.suggestion}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='suggestion' fontSize={"16px"}>Suggestion</FormLabel>
              <Textarea
                placeholder="How to help us improve"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="suggestion"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.suggestion}
              />
              {touched.suggestion && errors.suggestion && (
                <FormErrorMessage>{errors.suggestion}</FormErrorMessage>
              )}
            </FormControl>
            
            <Flex justifyContent={"center"} alignItems={"center"} my={5}>
              <Button
                mt={10}
                width={"50%"}
                background={"#22944E"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Saving ..."
              >
                Submit
              </Button>
            </Flex>
            
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default FeedBackForm;