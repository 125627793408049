import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  Image,
  Avatar,
  Box,
  Checkbox,
  Button,
  UnorderedList,
  ListItem,
  Stack,
} from "@chakra-ui/react";
import DotLoader from "../components/loaders/DotLoader";
import successImg from "../Assets/Images/mark-attendance.png";
import logo from "../Assets/Images/ncs-logo.png";

const MarkAttendance = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const guestId = parseInt(searchParams.get("guestId"), 10);
  const eventId = parseInt(searchParams.get("eventId"), 10);
  const [profileDetails, setProfileDetails] = useState(null);

  const markAttendance = useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/Registration/mark-attendance?guestId=${guestId}`,
        "post"
      );
      if (response?.isSuccessful === true) {
        setLoading(false);
        setSuccess(true);
        setSuccessMsg(response?.successMessage);
        setProfileDetails(response?.data);
      } else {
        setLoading(false);
        setError(
          response?.response?.data?.error?.responseDescription ||
            "Failed to mark attendance, please try again"
        );
      }
    } catch (error) {
      setLoading(false);
      setError(
        error?.payload?.message ||
          "Failed to mark attendance, please check your network"
      );
    } finally {
      setLoading(false);
    }
  }, [guestId]);

  const handleSubmitMaterials = async () => {
    setSubmitLoading(true);
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/Material/GuestMaterials`,
        "post",
        { guestId, eventId, materialIds: selectedMaterials }
      );
      if (response?.isSuccessful) {
        setSuccessMsg("Given Items Updated");
        window.location.reload();
      } else {
        setError("Failed to submit materials, please try again");
      }
    } catch (error) {
      setError("Failed to submit materials, please check your network");
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    markAttendance();
    // dispatch(fetchAllEvents());
  }, [markAttendance]);

  const handleCheckboxChange = (materialId) => {
    setSelectedMaterials((prevSelected) =>
      prevSelected.includes(materialId)
        ? prevSelected.filter((id) => id !== materialId)
        : [...prevSelected, materialId]
    );
  };

  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={5}
      py={"50px"}
    >
      <Image src={logo} alt="NCS LOGO" w={"180px"} height={"70px"} />
      {loading ? (
        <DotLoader />
      ) : (
        <>
          {error && (
            <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )}
          {success && (
            <>
              <Flex>
                <Text
                  color={"#515151"}
                  fontWeight={"700"}
                  fontSize={"24px"}
                  lineHeight={"36.7px"}
                >
                  {successMsg}
                </Text>
                <Image
                  src={successImg}
                  alt="Success Image"
                  width={"30px"}
                  ml={2}
                />
              </Flex>
              <Box
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={5}
                mt={5}
                textAlign="left"
              >
                <Flex>
                  <Avatar
                    size="2xl"
                    name={profileDetails?.firstName}
                    src={profileDetails?.profileUrl}
                    mr={5}
                  />
                  <Box>
                    <Box mb={2}>
                      <Text fontSize="sm" color={"#808080"}>
                        {" "}
                        Name:
                      </Text>
                      <Text fontWeight="bold" fontSize="lg">
                        {profileDetails?.firstName}
                      </Text>
                    </Box>
                    <Box mb={2}>
                      <Text fontSize="sm" color={"#808080"}>
                        {" "}
                        Email:
                      </Text>
                      <Text fontWeight="bold" fontSize="lg">
                        {profileDetails?.email}
                      </Text>
                    </Box>
                    <Box mb={2}>
                      <Text fontSize="sm" color={"#808080"}>
                        {" "}
                        Membership Type:
                      </Text>
                      <Text fontWeight="bold" fontSize="lg">
                        {profileDetails?.membershipType}
                      </Text>
                    </Box>
                    <Box mb={2}>
                      <Text fontSize="sm" color={"#808080"}>
                        {" "}
                        Event Name:
                      </Text>
                      <Text fontWeight="bold" fontSize="lg">
                        {profileDetails?.eventName}
                      </Text>
                    </Box>
                    <Box mb={2}>
                      <Text fontSize="sm" color={"#808080"}>
                        {" "}
                        Registration Type:
                      </Text>
                      <Text fontWeight="bold" fontSize="lg">
                        {profileDetails?.registrationType
                          ? profileDetails?.registrationType
                          : "Unavailable"}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="sm" color={"#808080"}>
                        {" "}
                        Materials Given:
                      </Text>
                      <UnorderedList fontSize="sm">
                        {profileDetails?.guestMaterials?.map(
                          (material, index) => (
                            <ListItem key={index} ml={2}>
                              {material.material}
                            </ListItem>
                          )
                        )}
                      </UnorderedList>
                    </Box>
                  </Box>
                </Flex>
              </Box>
              {profileDetails?.isAllMaterialGiven !== true ? (
                <Box>
                  <Text fontSize="sm" color={"#808080"}>
                    {" "}
                    Select Given Materials:
                  </Text>
                  <Stack pl={6} spacing={1}>
                    {profileDetails?.materials?.map((material) => (
                      <Checkbox
                        key={material.id}
                        isChecked={selectedMaterials.includes(material.id)}
                        onChange={() => handleCheckboxChange(material.id)}
                      >
                        {material.name}
                      </Checkbox>
                    ))}
                  </Stack>

                  <Button
                    mt={3}
                    colorScheme="green"
                    isLoading={submitLoading}
                    onClick={handleSubmitMaterials}
                  >
                    Submit
                  </Button>
                </Box>
              ) : (
                <>
                  <Text color={"green"} fontWeight={"bold"} mt={3}>
                    All Materials given
                  </Text>
                </>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default MarkAttendance;
