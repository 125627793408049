import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Flex,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  Select,
  Link,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { bulkUploadGuests } from "../redux/slices/membershipMgtSlice";
import { bulkUploadMemberSchema } from "../utils/formValidationSchema";
import { selectEvents } from "../redux/slices/eventSlice";

const BulkUploadGuests = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const initialValues = {
    eventId: "",
    file: "",
  };

  // useEffect(() => {
  //   dispatch(fetchAllEvents());
  // }, [dispatch]);
  const events = useSelector(selectEvents);

  const downloadTemplate = () => {
    window.location.href =
      "https://ncsevent.vatebra.org/api/Registration/download-bulk-registration-template";
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    setError("");
    try {
      const formData = {
        eventId: values?.id,
        file: values?.file,
      };

      const response = await dispatch(bulkUploadGuests(formData));
      // console.log("response", response);

      if (response?.payload?.isSuccessful === true) {
        setLoading(false);
        dispatch(setFormModal({ status: false }));
        // await dispatch(fetchUploadedMembers());
      } else {
        setLoading(false);
        setError(
          response?.payload?.errors?.file?.map((errors) => errors) ||
            response?.payload?.errors ||
            "Failed to upload guests. Please try again"
        );
      }
    } catch (error) {
      setError(error?.message || "Something went wrong. Please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(", ")}</AlertTitle>
        </Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={bulkUploadMemberSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <FormControl
              mt={4}
              isInvalid={errors.file && touched.file}
              color="#121212"
              fontFamily={"inter"}
            >
              {" "}
              <FormLabel htmlFor="file" fontSize={"16px"}>
                Select Event
              </FormLabel>
              <Select
                id="id"
                name="id"
                placeholder="Select event"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
              >
                {events.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                ))}
              </Select>
              <FormLabel htmlFor="file" fontSize={"16px"}>
                Upload File
              </FormLabel>
              <Input
                type="file"
                name="file"
                id="file"
                placeholder='accepts ".csv" files'
                fontSize={"small"}
                bg={"#F3F3F3"}
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
                accept=".csv, .xlsx"
              />
              <Text color={"#D11A2A"}> accepts ".csv, .xlsx" files</Text>
              {touched.file && errors.file && (
                <FormErrorMessage>{errors.file}</FormErrorMessage>
              )}
            </FormControl>

            <Flex justifyContent={"Flex-end"} alignItems={"center"} gap={5}>
              <Button
                mt={4}
                fontSize={"14px"}
                fontWeight={"500"}
                lineHeight={"16px"}
                width={"118px"}
                bg={"brand.primary"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Saving ..."
              >
                Upload
              </Button>
            </Flex>

            <Flex mt={4} justifyContent={"flex-end"}>
              <Text>
                Download template{" "}
                <Link color={"green"} onClick={downloadTemplate} download>
                  here
                </Link>
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BulkUploadGuests;
