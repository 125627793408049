import React from 'react';
import {Flex, Button, Select  } from '@chakra-ui/react';
import { PiPlusBold } from "react-icons/pi";
import SearchAndFilter from '../SearchAndFilter';

const CreateAndFilter = ({onClick,handleBulkUpload, createText, showFilter = false, showBulkUpload = false}) => {
  return (
    <Flex alignItems={'center'} gap={5} flexDirection={['column','column', 'row', 'row']}>
        <Button borderRadius={"3px"} bg={"brand.primary"} leftIcon={<PiPlusBold />} color="white"
            onClick={onClick} px={8} _hover={{ backgroundColor: '' }} 
        >
            {createText}
        </Button>
        {
            showBulkUpload ? (
            <Button borderRadius={"3px"} bg={"brand.primary"} leftIcon={<PiPlusBold />} color="white"
                onClick={handleBulkUpload} px={8} _hover={{ backgroundColor: '' }} 
            >
                Bulk Upload
            </Button>
            ) : null
        }
        {
        showFilter &&
        (<Select bg={'#FFFFFF'}  focusBorderColor="0.5px solid brand.primary" _hover={{border: ""}} border={'1px solid #22944E'}
            _placeholder={{ color: "#8A8C8E" }} color={'brand.primary'} borderRadius={'0'} 
        >
            <option value='Filter by Ref Code' selected 
            >Filter by Code</option>
            <option value='Filter by Name' >Filter by Name</option>
            <option value='Filter by Status'>Filter by Status</option>
        </Select>) 
        }
        <SearchAndFilter />
    </Flex>
  )
}

export default CreateAndFilter;