import React from 'react';

const BreadCrumbs = ({breadcrumb}) => {
    return (
        <>
            {breadcrumb}
        </>
    );
};

export default BreadCrumbs;