import { addEventSchema } from "../utils/formValidationSchema";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { setFormModal } from "../redux/slices/modalSlice";
import { editEvent } from "../redux/slices/eventSlice";
import UploadButton from "./UploadButton";

const EditEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentEvent = useSelector((state) => state.events.currentEvent);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("Id", currentEvent.id);
    formData.append("Name", values.name);
    formData.append("EventType", values.eventType);
    formData.append("StartDate", values.startDate);
    formData.append("EndDate", values.endDate);
    formData.append("EventTime", values.eventTime);
    formData.append("Location", values.location);
    formData.append("Information", values.information);
    formData.append("CoverImage", values.image);

    try {
      const response = await httpRequest(
        `${BASE_URL}/EventManagement/update-event`,
        "post",
        formData,
        null,
        "multipart/form-data"
      );

      dispatch(setFormModal({ status: null }));
      dispatch(editEvent(response.data));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: currentEvent.name,
        eventType: currentEvent.eventType,
        startDate: new Date(currentEvent.startDate).toISOString().split("T")[0],
        endDate: new Date(currentEvent.startDate).toISOString().split("T")[0],
        information: currentEvent.information,
        location: currentEvent.location,
        eventTime: new Date(currentEvent.eventTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      }}
      validationSchema={addEventSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => (
        <Flex direction="column" rowGap={4} as={Form}>
          <FormControl isInvalid={errors.name && touched.name}>
            <FormLabel>Event Name</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"#F3F3F3"}
              name="name"
              focusBorderColor="#ffffff"
              borderRadius={"0"}
              borderColor={"#FFFFFF"}
              _placeholder={{ color: "#B4B1B1" }}
              _hover={{ borderColor: "#FFFFFF" }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.name}
            />
            {touched.name && errors.name && (
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.eventType && touched.eventType}>
            <FormLabel>Event Type</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"#F3F3F3"}
              name="eventType"
              focusBorderColor="#ffffff"
              borderRadius={"0"}
              borderColor={"#FFFFFF"}
              _placeholder={{ color: "#B4B1B1" }}
              _hover={{ borderColor: "#FFFFFF" }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.eventType}
            />
            {touched.eventType && errors.eventType && (
              <FormErrorMessage>{errors.eventType}</FormErrorMessage>
            )}
          </FormControl>
          <Flex columnGap={2}>
            <FormControl isInvalid={errors.startDate && touched.startDate}>
              <FormLabel>Start Date</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="startDate"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.startDate}
              />
              {touched.startDate && errors.startDate && (
                <FormErrorMessage>{errors.startDate}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.endDate && touched.endDate}>
              <FormLabel>End Date</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="endDate"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.endDate}
              />
              {touched.endDate && errors.endDate && (
                <FormErrorMessage>{errors.endDate}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <FormControl isInvalid={errors.eventTime && touched.eventTime}>
            <FormLabel>Event Time</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"#F3F3F3"}
              name="eventTime"
              focusBorderColor="#ffffff"
              borderRadius={"0"}
              borderColor={"#FFFFFF"}
              _placeholder={{ color: "#B4B1B1" }}
              _hover={{ borderColor: "#FFFFFF" }}
              type="time"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.eventTime}
            />
            {touched.eventTime && errors.eventTime && (
              <FormErrorMessage>{errors.eventTime}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.information && touched.information}>
            <FormLabel>Information</FormLabel>
            <Textarea
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"#F3F3F3"}
              name="information"
              focusBorderColor="#ffffff"
              borderRadius={"0"}
              borderColor={"#FFFFFF"}
              _placeholder={{ color: "#B4B1B1" }}
              _hover={{ borderColor: "#FFFFFF" }}
              onBlur={handleBlur}
              onChange={handleChange}
              rows={5}
              resize={"none"}
              value={values?.information}
            />
            {touched.information && errors.information && (
              <FormErrorMessage>{errors.information}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.location && touched.location}>
            <FormLabel>Location</FormLabel>
            <Input
              placeholder="Enter Here"
              fontSize={"small"}
              bg={"#F3F3F3"}
              name="location"
              focusBorderColor="#ffffff"
              borderRadius={"0"}
              borderColor={"#FFFFFF"}
              _placeholder={{ color: "#B4B1B1" }}
              _hover={{ borderColor: "#FFFFFF" }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.location}
            />
            {touched.location && errors.location && (
              <FormErrorMessage>{errors.location}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={errors.image}>
            <FormLabel>File input</FormLabel>
            {/* <ImageUpload /> */}
            <Input
              name="image"
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept="image/*"
              onChange={(e) => {
                setFieldValue("image", e.target.files[0]);
              }}
              onBlur={handleBlur}
            />
            {values.image ? (
              <div>
                <img src={URL.createObjectURL(values.image)} alt="Selected" />
              </div>
            ) : (
              <UploadButton />
            )}
            {touched.image && errors.image && (
              <FormErrorMessage>{errors.image}</FormErrorMessage>
            )}
          </FormControl>
          <Button
            mt={10}
            width={"100%"}
            background={"#22944E"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            isLoading={isLoading}
            loadingText="Updating..."
          >
            Save
          </Button>
        </Flex>
      )}
    </Formik>
  );
};

export default EditEvent;
