import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";

const initialState =  {
    hotels: [],
    loading: false,
    error: null,
    editHotel: "",
    hotelDetails: "",
};

export const fetchAllHotels = createAsyncThunk("hotelAccom/fetchAllHotels", async () => {
    const response = httpRequest(`${BASE_URL}/Hotel/get-all-hotel`);
  // console.log("fetchAllHotels", response);
    return response ;
});

export const createHotel = createAsyncThunk("hotelAccom/createHotel", async (arg, { rejectWithValue }) => {
    try {
     const response = await httpRequest(`${BASE_URL}/Hotel/create-hotel`, "post", arg );
    //   console.log("createHotel", response);
     if (response?.response?.data) {
         throw response;
     }
      return response;
     
    } catch (error) {
     console.log("error: ",error?.response?.data);
     return rejectWithValue(error?.response?.data);
    }
 });


const hotelAccomSlice = createSlice({
    name: "hotelAccom",
    initialState,

    reducers: {
        sethotelDetails: (state, { payload }) => {
            state.hotelDetails = payload;
        },
        seteditHotel: (state, { payload }) => {
            state.editHotel = payload;
        },
    },

    extraReducers: (builder) => {
        
        // fetch all hotels : 
        builder.addCase(fetchAllHotels.pending, (state) => {
            state.loading = true;
            state.error = null;
        });

        builder.addCase(fetchAllHotels.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.hotels = payload?.data;
            state.error = null;
        });

        builder.addCase(fetchAllHotels.rejected, (state, { payload }) => {
            state.loading = false;
            state.hotels = [];
            state.error = payload?.error?.responseDescription;
        });

        // create Hotel : 
        builder.addCase(createHotel.pending, (state) => {
          state.loading = true;
          state.error = null;
        });

        builder.addCase(createHotel.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.hotels.push(payload?.data);
          state.error = null;
        });

        builder.addCase(createHotel.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload?.responseDescription;
        });

    }
});



export const {sethotelDetails, seteditHotel}= hotelAccomSlice.actions;
export default hotelAccomSlice.reducer;