import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";

const initialState = {
  dashboard: "",
  loading: false,
  error: null,
};

export const fetchDashboard = createAsyncThunk(
  "Dashboard/fetchDashboard",
  async () => {
    const response = httpRequest(`${BASE_URL}/Dashboard/dashboard`);
    // console.log("This is ", response);
    return response;
  }
  // async () => {
  //   const response = httpRequest(`${BASE_URL}/Dashboard/dashboard`);
  //   console.log("Dashboard details", response);
  //   return response;
  // }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,

  // reducers: {
  //   setMemberDetails: (state, { payload }) => {
  //     state.memberDetails = payload;
  //   },
  //   setEditMember: (state, { payload }) => {
  //     state.editMember = payload;
  //   },
  // },

  extraReducers: (builder) => {
    builder.addCase(fetchDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboard = action?.payload?.data;
    });
    builder.addCase(fetchDashboard.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });
  },
});

export default dashboardSlice.reducer;
