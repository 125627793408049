import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../utils/httpRequest";
import { BASE_URL } from "../../config/settings";

const initialState =  {
    eventReg: [],
    allEvents:[],
    loading: false,
    error: null,
    eventDetails:"",
    eventRegDetails: "",
    memberCheckDetails: "",
    eventPaymentDetails:"",
};

export const fetchAllEvents = createAsyncThunk("eventRegistration/fetchAllEvents", async () => {
    const response = httpRequest(`${BASE_URL}/EventManagement/get-all-events`);
    // console.log("fetchAllEvents", response);
    return response ;
});

const eventRegistrationSlice = createSlice({
    name: "eventRegistration",
    initialState,

    reducers: {
        seteventDetails: (state, { payload }) => {
            state.eventDetails = payload;
        },

        seteventRegDetails: (state, { payload }) => {
            state.eventRegDetails = payload;
        },

        setmemberCheckDetails: (state, { payload }) => {
            state.memberCheckDetails = payload;
        },

        seteventPaymentDetails: (state, { payload }) => {
            state.eventPaymentDetails = payload;
        },

        removeMemberCheckDetails: (state, { payload }) => {
            state.memberCheckDetails = "";
        },

        removeEventPaymentDetails: (state, { payload }) => {
            state.eventPaymentDetails = "";
        },


    },

    extraReducers: (builder) => {
        builder.addCase(fetchAllEvents.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchAllEvents.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.allEvents = payload?.data;
            state.error = null;
        });

        builder.addCase(fetchAllEvents.rejected, (state, {payload}) => {
            state.loading = false;
            state.allEvents = [];
            state.error = payload?.error?.responseDescription;
        })
    }
});

export const {seteventRegDetails, seteventDetails, setmemberCheckDetails, removeMemberCheckDetails, seteventPaymentDetails, removeEventPaymentDetails}= eventRegistrationSlice.actions;
export default eventRegistrationSlice.reducer;