import React,  {useState } from 'react';
// import { format } from 'date-fns';
import { useSelector,  useDispatch } from 'react-redux';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import {setFormModal} from "../redux/slices/modalSlice";
import {fetchUploadedMembers} from "../redux/slices/membershipMgtSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";

const EditMemberMgt = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const editMember = useSelector((state) => state?.membershipMgt?.editMember);
    const id = editMember.id;
    
    const initialValues = {
        membershipCode: editMember?.memberShipCode,
        firstName:editMember?.firstName,
        lastName:editMember?.lastName,
        phoneNumber:editMember?.phoneNumber,
        // email:editMember.email,
        membershipType:editMember?.membershipType || "",
        // dateJoined:editMember.dateJoined && format(new Date(editMember.dateJoined), 'yyyy-MM-dd'),
    };

    const handleSubmit = async (values) => {
        setError("");
        setLoading(true);
        const formData = {
            memberShipCode:values.membershipCode,
            firstName:values.firstName,
            lastName:values.lastName,
            // email:values.email,
            phoneNumber: values.phoneNumber,
            membershipType:values.membershipType,
            // dateJoined:values.dateJoined,
            // "isActive": editMember.isActive
        };

    try {
        const response = await httpRequest(
          `${BASE_URL}/MembershipManagement/edit-uploadedMember?memberlId=${id}`, 
          "post", 
          formData,
        );
  
        if(response.isSuccessful === true){
          setLoading(false);
          await dispatch(fetchUploadedMembers());
          dispatch(setFormModal({ status: false }));
        }else{
          setError(response?.response?.data?.responseDescription || "Failed to edit member. Please try again");
      }
      } catch (error) {
        setError(error?.message);
      }finally{
        setLoading(false);
      }
    };

  return (
    <>
    {
      error &&(
      <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{ error}</AlertTitle>
      </Alert>
      )
    }
    <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
    >
        {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
            <FormControl mt={4} isInvalid={errors.membershipCode && touched.membershipCode}
                color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='membershipCode' fontSize={"16px"}>Membership code</FormLabel>
            <Input
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="membershipCode"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.membershipCode}
            />
            {touched.membershipCode && errors.membershipCode && (
                <FormErrorMessage>{errors.membershipCode}</FormErrorMessage>
            )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.firstName && touched.firstName}
                color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='firstName' fontSize={"16px"}>First Name</FormLabel>
            <Input
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="firstName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.firstName}
            />
            {touched.firstName && errors.firstName && (
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
            )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.lastName && touched.lastName}
                color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='lastName' fontSize={"16px"}>Last Name</FormLabel>
            <Input
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="lastName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.lastName}
            />
            {touched.lastName && errors.lastName && (
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
            )}
            </FormControl>
            {/* <FormControl mt={4} isInvalid={errors.email && touched.email}
                color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='email' fontSize={"16px"}>Email Address</FormLabel>
            <Input
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="email"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.email}
            />
            {touched.email && errors.email && (
                <FormErrorMessage>{errors.email}</FormErrorMessage>
            )}
            </FormControl> */}
            <FormControl mt={4} isInvalid={errors.phoneNumber && touched.phoneNumber}
            color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='phoneNumber' fontSize={"16px"}>Phone Number</FormLabel>
                <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="phoneNumber"
                    focusBorderColor="#ffffff"
                    borderRadius={'0'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="tel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.phoneNumber}
                />
                {touched.phoneNumber && errors.phoneNumber && (
                    <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.membershipType && touched.membershipType}
                color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='membershipType'  fontSize={"16px"}>Membership Type</FormLabel>
            <Select
                name="membershipType"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.membershipType}
                >
                
                <option  value={'MEMBER'}>Member</option>
                <option  value={'Non Member'}>Non Member</option>
                <option  value={'FELLOW'}>Fellow</option>
                <option  value={'Fellow-R'}>Fellow-R</option>
                <option  value={'ASSOCIATE'}>Associate</option>
                
                </Select>
            {touched.membershipType && errors.membershipType && (
                <FormErrorMessage>{errors.membershipType}</FormErrorMessage>
            )}
            </FormControl>
            
            {/* <FormControl mt={4} isInvalid={errors.dateJoined && touched.dateJoined}
            color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='dateJoined' fontSize={"16px"}> Date Joined</FormLabel>
            <Input
                type="date"
                required
                borderRadius={"0"}
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="dateJoined"
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.dateJoined}
            />
            {touched.dateJoined && errors.dateJoined && (
                <FormErrorMessage>{errors.dateJoined}</FormErrorMessage>
            )}
            </FormControl> */}
            <Button
            mt={10}
            width={"100%"}
            background={"#22944E"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            isLoading={loading}
            loadingText="Saving ..."
            >
            Save
            </Button>
        </Form>
        )}
    </Formik>
    </>
  )
}

export default EditMemberMgt;